import UnknownBlock from './Unknown.vue';
import TextBlock from './Text.vue';
import ImageBlock from './Image.vue';
import AudioBlock from './Audio.vue';
import VideoBlock from './Video.vue';
import AttachmentBlock from './Attachment.vue';
import IframeBlock from './Iframe.vue';
import SeperatorBlock from './Seperator.vue';
import ButtonBlock from './Button.vue';
import SlideshowBlock from './slideshow/index.vue';
import MapBlock from './Map.vue';
import TableBlock from './Table.vue';
import InsightBlock from './InsightDashboard.vue';
import CoverpageBlock from './Coverpage.vue';

const types = [
  {
    type: 'text',
    tools: ['sizes', 'padding', 'duplicate', 'delete'],
  },
  {
    type: 'image',
    tools: [
      'media',
      'figure-settings',
      'floats',
      'sizes',
      'padding',
      'duplicate',
      'settings',
      'delete',
    ],
  },
  {
    type: 'audio',
    tools: ['media', 'floats', 'sizes', 'padding', 'duplicate', 'delete'],
  },
  {
    type: 'video',
    tools: [
      'media',
      'floats',
      'sizes',
      'padding',
      'duplicate',
      'settings',
      'delete',
    ],
  },
  {
    type: 'attachment',
    tools: ['floats', 'sizes', 'padding', 'duplicate', 'delete'],
  },
  {
    type: 'iframe',
    tools: ['media', 'settings', 'sizes', 'duplicate', 'delete'],
  },
  {
    type: 'seperator',
    tools: ['sizes', 'padding', 'duplicate', 'delete'],
  },
  {
    type: 'button',
    tools: ['settings', 'padding', 'sizes', 'duplicate', 'delete'],
  },
  {
    type: 'slideshow',
    tools: [],
  },
  {
    type: 'map',
    tools: ['settings', 'floats', 'padding', 'sizes', 'duplicate', 'delete'],
  },
  {
    type: 'table',
    tools: ['settings', 'sizes', 'padding', 'duplicate', 'delete'],
  },
  {
    type: 'insight',
    tools: ['settings', 'sizes', 'padding', 'duplicate', 'delete'],
  },
  {
    type: 'coverpage',
    tools: ['media', 'settings', 'delete'],
  },
];

const _getComponentByType = (type) => {
  return types.find((t) => t.type == type);
};

const getComponentName = (type) => {
  if (_getComponentByType(type)) {
    return type + '-block';
  }

  return 'unknown-block';
};

const getComponentNameFromId = (type) => {
  return getComponentName(type);
};

const getToolsByComponentType = (type) => {
  return _getComponentByType(type).tools;
};

// types that cannot be added as a child blocks
const typeIsSupportedAsChildBlock = (type) => {
  return ![
    'image',
    'video',
    'slideshow',
    'map',
    'table',
    'insight',
    'coverpage',
  ].includes(type);
};

const typeHasTool = (type, tool) => {
  return _getComponentByType(type).tools.includes(tool);
};

export default {
  components: {
    UnknownBlock,
    TextBlock,
    ImageBlock,
    AudioBlock,
    VideoBlock,
    AttachmentBlock,
    IframeBlock,
    SeperatorBlock,
    ButtonBlock,
    SlideshowBlock,
    MapBlock,
    TableBlock,
    InsightBlock,
    CoverpageBlock,
  },
  getComponentName,
  getComponentNameFromId,
  getToolsByComponentType,
  typeIsSupportedAsChildBlock,
  typeHasTool,
};
