import { ref, provide } from 'vue';
import { PopoverData } from '../../gather-table-horizontal/types/popover-data';
import { Sample } from '@component-library/enviro';
import { AVAILABLE_PERMISSIONS } from '@component-library/company-role-profile';
import useViewRestriction from '@component-library/composables/useViewRestriction';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';

export default function usePopoverHandler() {
  const { isViewOnly } = useViewRestriction(AVAILABLE_PERMISSIONS.GATHER);

  const gatherAppStore = useGatherSchemaStore();

  const popoverData = ref<PopoverData | null>(null);
  const itemPopoverData = ref<Sample | null>(null);

  const openFieldPopover = (allData: PopoverData) => {
    const templateTabId = allData.sample.template_tab_id!;
    if (isReadOnly(templateTabId)) {
      return;
    }

    itemPopoverData.value = null;

    if (!allData.inputValue) {
      allData.inputValue = {
        template_tab_id: templateTabId,
        template_field_id: allData.field.id,
        template_section_index: allData.sectionIndex,
        template_section_id: allData.field.template_section_id,
        value: null,
        value2: null,
        options: null,
        sample_id: allData.sample.id,
      } as any;
    }

    if (
      popoverData.value &&
      allData.inputValue &&
      popoverData.value.inputValue.id === allData.inputValue.id
    ) {
      popoverData.value = null;
      return;
    }

    popoverData.value = allData;
  };

  const openItemPopover = (item: Sample) => {
    if (isReadOnly(item.template_tab_id!)) {
      return;
    }

    popoverData.value = null;

    if (itemPopoverData.value && itemPopoverData.value.id === item.id) {
      itemPopoverData.value = null;
      return;
    }

    itemPopoverData.value = item;
  };

  const isReadOnly = (tabId: number) => {
    if (isViewOnly()) {
      return true;
    }

    return gatherAppStore.isAppReadOnly(tabId);
  };

  provide('openFieldPopover', openFieldPopover);
  provide('openItemPopover', openItemPopover);
  provide('popoverData', popoverData);
  provide('itemPopoverData', itemPopoverData);

  return {
    popoverData,
    itemPopoverData,
    isReadOnly,
  };
}
