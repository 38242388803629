import { FEATURES } from '@component-library/feature-manager';
import { App } from '@component-library/gather';
import { useProjectStore } from '@component-library/store/project';
import axios from 'axios';
import { cloneDeep, isEmpty, isEqual, omit } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { NEW_DASHBOARD_ID, NEW_DASHBOARD_ITEM_ID } from '../constants';
import * as SWATCHES from '../constants/swatches';
import {
  generateDashboardItemLayoutId,
  getDashboardCellInfo,
  getNextVacantDashboardItemLayout,
  preprocessOptions,
} from '../utils';

export type DashboardItemOptions = {
  info?: {
    viewModeId?: number;
    eventSourceIds?: number[];
    eventSourceEnabled?: boolean;
    selection?: any;
    templateId?: number;
    childAppId?: number;
    parentAppId?: number;
    sectionId?: number;
    dimensionFieldId?: number;
    statisticTypeId?: number;
    measureFieldId?: number;
    measureFieldIds?: number[];
    aggregateFunctionId?: number;
    showStatisticOfNoData?: boolean;
    showStatisticEqualToZero?: boolean;
    limitPerPage?: number;
    title?: string;
    groupByParentApp?: number;
    groupByChildAppTitle?: string;
    numberOfPlannedSamples?: number;
    textFieldId?: number;
    yAxisMeasureFieldId?: number;
    xAxisMeasureFieldId?: number;

    dateFormat?: number | null;
    excludedSampleIds?: null | number[];
    includedSampleIds?: null | number[];
  };

  style?: {
    canvas?: {
      backgroundColor?: string;
      borderColor?: string;

      titleColor?: string;
      xAxisTickColor?: string;
      xAxisLabelColor?: string;
      yAxisTickColor?: string;
      yAxisLabelColor?: string;
      legendLabelColor?: string;
      percentageColor?: string;
      percentageSize?: number;
    };
    graph?:
    | {
      backgroundColor?: string | Record<string, string>;
      borderColor?: string;
      useCurvedLines?: boolean;
    }[]
    | {
      backgroundColor?: string;
      useCurvedLines?: boolean;
      defaultBackgroundColors?: string[];
    };

    axes?: {
      yAxis?: {
        yAxisLabel?: string;
        beginAtZero?: boolean;
        precision?: number;
        max?: number;
        min?: number;
        stepSize?: number;
      };
      xAxis?: {
        xAxisLabel?: string;
      };
    };
  };
};

export type ToolTypeKey = string;

export type DashboardItemDataPaginatedGroups = {
  groups: {
    title: string;
    pages: {
      title: string;
      data: any;
    }[];
  }[];
};

export type DashboardItemData = DashboardItemDataPaginatedGroups | any;

export type DashboardItem = {
  id: number;
  toolType: ToolTypeKey;
  options?: DashboardItemOptions | null;
  data?: DashboardItemData | null;
  layout?: any;
  loading?: boolean;
};

export type Dashboard = {
  id: number;
  layout?: number;
  items: DashboardItem[];
  config: any;
  title: string;
  description: null | string;
  autoRefreshConfig: any;
};

export enum DashboardActionAction {
  ADD = 'add',
  EDIT = 'edit',
  DELETE = 'delete',
}

export type DashboardAction = {
  dashboardId: number;
  action: DashboardActionAction;
};

export const TOOL_TYPES = {
  BAR_CHART: 'bar-chart',
  LINE_CHART: 'line-chart',
  SCATTER_CHART: 'scatter-chart',
  PIE_CHART: 'pie-chart',
  PERCENTAGE_CHART: 'percentage',
  WORD_CLOUD_CHART: 'word-cloud',
  DISPLACEMENT_GRAPH: 'displacement-graph',
} as const;

export type DataInsightTool = {
  title: string;
  icon: string;
  type: ToolTypeKey;
  feature_flag?: string;
};

export const tools: readonly DataInsightTool[] = [
  {
    title: 'Bar',
    icon: 'fal fa-chart-bar',
    type: TOOL_TYPES.BAR_CHART,
  },
  {
    title: 'Line',
    icon: 'fal fa-chart-line',
    type: TOOL_TYPES.LINE_CHART,
  },
  {
    title: 'Pie',
    icon: 'fal fa-chart-pie',
    type: TOOL_TYPES.PIE_CHART,
  },
  {
    title: 'Scatter',
    icon: 'fal fa-chart-scatter',
    type: TOOL_TYPES.SCATTER_CHART,
  },
  {
    title: 'Percentage',
    icon: 'fal fa-percentage',
    type: TOOL_TYPES.PERCENTAGE_CHART,
  },
  {
    title: 'Word Cloud',
    icon: 'fal fa-cloud',
    type: TOOL_TYPES.WORD_CLOUD_CHART,
  },
  {
    title: 'Displacement Graph',
    icon: 'fad fa-hexagon',
    feature_flag: FEATURES.DISPLACEMENT_GRAPH,
    type: TOOL_TYPES.DISPLACEMENT_GRAPH,
  },
];

export type DataInsightsItem = {
  id: number;
  title: string;
};

export const useInsightsStore = defineStore('insights', () => {
  const dashboards = ref<Dashboard[]>([]);
  const apps = ref<App[]>([]);
  const loadingDashboards = ref(false);
  const selectedDashboardItemId = ref<number | null>(null);
  const selectedDashboardId = ref<number | null>(null);
  const selectedDashboardSnapshot = ref<Dashboard | null>(null);
  const dashboardAction = ref<null | DashboardAction>(null);
  const dashboardRuntimeData = ref<Record<number, Record<number, any>>>({});
  const autoRefreshIntervalIds = ref<Record<number, NodeJS.Timeout>>({});
  const templateDetails = ref({});
  const templateSamples = ref({});
  const toolContentMaxHeight = ref(0);
  const swatches: string[] = [];
  for (let i = 0; i < 15; i++) {
    swatches.push(SWATCHES[`COLOR_${i}`]);
  }
  const highlightColor = ref(SWATCHES.COLOR_354);
  const dashboardSize = ref({ width: 0, height: 0 });

  const selectedDashboard = computed(() => {
    if (!selectedDashboardId.value) {
      return undefined;
    }
    return findDashboardById(selectedDashboardId.value);
  });

  const selectedDashboardItem = computed(() => {
    if (!selectedDashboardId.value || !selectedDashboardItemId.value) {
      return undefined;
    }
    return findDashboardItemById(
      selectedDashboardId.value,
      selectedDashboardItemId.value
    );
  });

  function findDashboardById(id: number) {
    return dashboards.value.find((dashboard) => dashboard.id === id);
  }

  function findDashboardItemById(dashboardId: number, id: number) {
    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      return null;
    }
    return dashboard.items.find((item) => item.id === id);
  }

  function getDashboardItemStatuses(dashboardId, dashboardItemId) {
    const dashboardStatus = dashboardRuntimeData.value[dashboardId] ?? {};
    return (
      dashboardStatus[dashboardItemId] ?? {
        loading: false,
        breathing: false,
        hasOutdatedData: false,
        checkBackgroundColor: false,
        clearSelection: false,
        // This property's value is used to add an event source id if it
        // doesn't exist in the eventSourceIds array or remove an event
        // source id if it exists in the eventSourceIds array.
        eventSourceIdToToggle: null,
        clearEventSourceIds: false,
      }
    );
  }

  function getObservers(dashboardId, eventSourceId) {
    const observers: DashboardItem[] = [];
    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      throw new Error(`Dashboard with id ${dashboardId} not found`);
    }

    for (let item of dashboard.items) {
      const eventSourceIds = item.options?.info?.eventSourceIds;
      if (!!eventSourceIds && eventSourceIds.includes(eventSourceId)) {
        observers.push(item);
      }
    }

    return observers;
  }

  function isDashboardItemBeingEdited(dashboardItemId: number) {
    return selectedDashboardItemId.value === dashboardItemId;
  }

  function findTool(toolType: ToolTypeKey) {
    return tools.find((tool) => tool.type === toolType);
  }

  async function saveDashboardItem({ dashboardId, dashboardItemId }) {
    const dashboardItem = findDashboardItemById(dashboardId, dashboardItemId);
    if (!dashboardItem) {
      throw new Error(`Dashboard item with id ${dashboardItemId} not found`);
    }

    const { data: savedDashboardItem } = await axios.post(
      '/data-insights/dashboard-item',
      {
        id:
          dashboardItem.id === NEW_DASHBOARD_ITEM_ID ? null : dashboardItem.id,
        tool_type: dashboardItem.toolType,
        layout: omit(dashboardItem.layout, 'i'),
        options: dashboardItem.options,
        data: dashboardItem.data,
        dashboard_id: dashboardId,
      }
    );

    if (dashboardItem.id === NEW_DASHBOARD_ITEM_ID) {
      updateDashboardItem({
        dashboardId,
        dashboardItemId: dashboardItem.id,
        update: {
          id: savedDashboardItem.id,
        },
      });
    }
  }

  function updateDashboardItem({ dashboardId, dashboardItemId, update }) {
    const dashboardItem = findDashboardItemById(dashboardId, dashboardItemId);

    // Under the following situations the dashboardItem is null
    // when this method is invoked:
    // 1) The dashboard item has been deleted while it is loading.
    if (!dashboardItem) {
      return;
    }

    const nextDashboard = dashboardItem;
    for (let key in update) {
      nextDashboard[key] = update[key];
    }
  }

  function updateDashboardRuntimeData({
    dashboardId,
    dashboardItemId,
    update,
  }) {
    const dashboardStatus = dashboardRuntimeData.value[dashboardId] ?? {};
    const dashboardItemStatuses = getDashboardItemStatuses(
      dashboardId,
      dashboardItemId
    );
    dashboardRuntimeData.value = {
      ...dashboardRuntimeData.value,
      [dashboardId]: {
        ...dashboardStatus,
        [dashboardItemId]: {
          ...dashboardItemStatuses,
          ...update,
        },
      },
    };
  }

  function replaceDashboard({ dashboardId, nextDashboard }) {
    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      throw new Error(`Dashboard with id ${dashboardId} not found`);
    }
    const index = dashboards.value.indexOf(dashboard);
    if (index === -1) {
      throw new Error(`Dashboard with id ${dashboardId} not found`);
    }
    dashboards.value.splice(index, 1, nextDashboard);
    if (selectedDashboardId.value === dashboardId) {
      selectedDashboardId.value = nextDashboard.id;
    }
  }

  async function updateDashboardItemOptions({
    dashboardId,
    dashboardItemId,
    update,
  }) {
    const dashboardItem = findDashboardItemById(dashboardId, dashboardItemId);
    if (!dashboardItem) {
      throw new Error(`Dashboard item with id ${dashboardItemId} not found`);
    }
    updateDashboardItem({
      dashboardId,
      dashboardItemId,
      update: { options: { ...dashboardItem.options, ...update } },
    });
  }

  const dashboardDefaultConfig = computed(() => {
    const colNum = 12;
    const rowNum = 12;
    const margin = 4;
    const { colWidth, rowHeight } = getDashboardCellInfo(
      { ...dashboardSize.value },
      colNum,
      rowNum,
      margin
    );
    return {
      colNum,
      rowNum,
      colWidth,
      rowHeight,
      isDraggable: true,
      isResizable: true,
      isMirrored: false,
      isVerticalCompact: true,
      margin,
      isCssTransformsUsed: true,
    };
  });

  async function loadDashboards(project_id?: string | number) {
    project_id = project_id ?? useProjectStore().currentProject?.project_id;
    try {
      loadingDashboards.value = true;
      const {
        data: { dashboards: dashboardModels },
      } = await axios.get(`data-insights/dashboards`, {
        params: { project_id },
      });
      dashboards.value = dashboardModels.map(
        ({
          id,
          title,
          description,
          config,
          auto_refresh_config: autoRefreshConfig,
          items,
        }) => ({
          id,
          title,
          description,
          config: isEmpty(config) ? dashboardDefaultConfig.value : config,
          autoRefreshConfig,
          items: items.map((item) => ({
            id: item.id,
            toolType: item.tool_type,
            layout: { ...item.layout, i: generateDashboardItemLayoutId() },
            options: preprocessOptions(item.tool_type, item.options, item.data),
            data: item.data,
          })),
        })
      );
    } catch (e) {
      throw e;
    } finally {
      loadingDashboards.value = false;
    }
  }

  async function loadApps() {
    const { data } = await axios.get('/gather/tabs');
    apps.value = data.tabs;
  }

  function takeSnapshotOfSelectedDashboard() {
    if (!selectedDashboardId.value) {
      throw new Error(
        'takeSnapshotOfSelectedDashboard: No dashboard is selected'
      );
    }
    const selectedDashboard = findDashboardById(selectedDashboardId.value);
    if (!selectedDashboard) {
      throw new Error(
        'takeSnapshotOfSelectedDashboard: The selected dashboard is not found'
      );
    }
    const snapshot = cloneDeep(selectedDashboard ?? {});

    snapshot.items.forEach((item) => {
      // item.layout.i is used as the key of a GridItem component which is the parent
      // of a DashboardItem component. When the selectedDashboard snapshot is used to
      // restore data, watchers in the DashboardItem component should be suppressed.
      // Resetting item.layer.i causes the GridItem component being re-rendered, so does
      // the DashboardItem component inside, so that watchers in the DashboardItem
      // component are suppressed.
      item.layout.i = generateDashboardItemLayoutId();
    });

    selectedDashboardSnapshot.value = snapshot;
  }

  function setSelectedDashboardItemId(dashboardItemId: number | null) {
    selectedDashboardItemId.value = dashboardItemId;
  }

  async function addDashboardItem({ dashboardId, dashboardItem }) {
    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      throw new Error(`Dashboard with id ${dashboardId} not found`);
    }
    const { items: dashboardItems } = dashboard;
    const nextDashboard = {
      ...dashboard,
      items: [...dashboardItems, dashboardItem],
    };
    await replaceDashboard({
      dashboardId,
      nextDashboard,
    });
  }

  async function deleteDashboard(dashboardId: number) {
    if (!!dashboardId && dashboardId !== NEW_DASHBOARD_ID) {
      await axios.delete(`/data-insights/dashboards/${dashboardId}`);
    }
    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      throw new Error(`Dashboard with id ${dashboardId} not found`);
    }
    const index = dashboards.value.indexOf(dashboard);
    dashboards.value.splice(index, 1);
    if (selectedDashboardId.value === dashboardId) {
      selectedDashboardId.value =
        dashboards.value.length > 0 ? dashboards.value[0].id : null;
    }
  }

  async function deleteDashboardItem({ dashboardId, dashboardItemId }) {
    if (!!dashboardItemId && dashboardItemId !== NEW_DASHBOARD_ITEM_ID) {
      await axios.delete(`/data-insights/dashboard-item/${dashboardItemId}`);
    }

    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      throw new Error(`Dashboard with id ${dashboardId} not found`);
    }

    const { items: dashboardItems } = dashboard;
    const dashboardItem = findDashboardItemById(dashboardId, dashboardItemId);
    if (!dashboardItem) {
      throw new Error(`Dashboard item with id ${dashboardItemId} not found`);
    }
    const index = dashboardItems.indexOf(dashboardItem);
    const nextDashboardItems = [...dashboardItems];
    nextDashboardItems.splice(index, 1);
    const nextDashboard = {
      ...dashboard,
      items: nextDashboardItems,
    };
    await replaceDashboard({ dashboardId, nextDashboard });
    if (selectedDashboardItemId.value === dashboardItemId) {
      setSelectedDashboardItemId(null);
    }
  }

  function clearSelectedDashboardSnapshot() {
    selectedDashboardSnapshot.value = null;
  }

  async function saveSelectedDashboardItem() {
    if (!selectedDashboardItemId.value) {
      throw new Error(
        'saveSelectedDashboardItem: No dashboard item is selected'
      );
    }
    if (!selectedDashboard.value) {
      throw new Error('saveSelectedDashboardItem: No dashboard is selected');
    }

    const outdatedDashboardItemIds = selectedDashboard.value.items
      .filter((item: any) => {
        if (item.id === selectedDashboardItemId.value) {
          return true;
        }
        if (!selectedDashboardSnapshot.value) {
          return false;
        }

        const itemSnapshot = selectedDashboardSnapshot.value.items.find(
          (itemSnapshot) => itemSnapshot.id === item.id
        );
        if (!itemSnapshot) {
          return false;
        }
        const itemWithoutLayoutId = { ...item, layout: omit(item.layout, 'i') };
        const itemSnapshotWithoutLayoutId = {
          ...itemSnapshot,
          layout: omit(itemSnapshot.layout, 'i'),
        };
        return !isEqual(itemWithoutLayoutId, itemSnapshotWithoutLayoutId);
      })
      .map((item) => item.id);
    for (let i = 0; i < outdatedDashboardItemIds.length; i++) {
      await saveDashboardItem({
        dashboardId: selectedDashboard.value.id,
        dashboardItemId: outdatedDashboardItemIds[i],
      });
    }

    setSelectedDashboardItemId(null);
    clearSelectedDashboardSnapshot();
  }

  async function cancelSelectedDashboardItem() {
    if (!selectedDashboardItemId.value) {
      return;
    }
    const dashboardId = selectedDashboardId.value;
    const dashboardItemId = selectedDashboardItemId.value;
    const nextDashboard = selectedDashboardSnapshot.value;

    setSelectedDashboardItemId(null);
    await replaceDashboard({
      dashboardId: dashboardId,
      nextDashboard,
    });
    clearSelectedDashboardSnapshot();

    if (dashboardItemId !== NEW_DASHBOARD_ITEM_ID) {
      await saveDashboardItem({
        dashboardId: dashboardId,
        dashboardItemId: dashboardItemId,
      });
    }
  }

  async function saveDashboard(dashboard: Dashboard) {
    const { data: savedDashboard } = await axios.post(
      '/data-insights/dashboard',
      {
        id: dashboard.id === NEW_DASHBOARD_ID ? null : dashboard.id,
        title: dashboard.title,
        description: dashboard.description,
        config: dashboard.config,
        auto_refresh_config: dashboard.autoRefreshConfig,
      }
    );
    let nextDashboard = dashboard;
    if (dashboard.id === NEW_DASHBOARD_ID) {
      nextDashboard = {
        ...dashboard,
        id: savedDashboard.id,
      };
      await replaceDashboard({
        dashboardId: dashboard.id,
        nextDashboard,
      });
    }
    return nextDashboard;
  }

  async function setDashboardSize(size: { width: number; height: number }) {
    dashboardSize.value = size;

    for (let i = 0; i < dashboards.value.length; i++) {
      const dashboard = dashboards.value[i];
      const config = dashboard.config;
      const { colWidth, rowHeight } = getDashboardCellInfo(
        size,
        config.colNum,
        config.rowNum,
        config.margin
      );
      const nextDashboard = {
        ...dashboard,
        config: {
          ...config,
          colWidth,
          rowHeight,
        },
      };
      await saveDashboard(nextDashboard);
      await replaceDashboard({
        dashboardId: dashboard.id,
        nextDashboard,
      });
    }
  }

  function getDashboardLayout(dashboard: Dashboard) {
    return dashboard.items.map((item) => item.layout);
  }

  function setAutoRefreshIntervalId({ dashboardId, autoRefreshIntervalId }) {
    autoRefreshIntervalIds.value = {
      ...autoRefreshIntervalIds.value,
      [dashboardId]: autoRefreshIntervalId,
    };
  }

  function setDashboardAction(newDashboardAction: DashboardAction | null) {
    dashboardAction.value = newDashboardAction;
  }

  function addDashboard(dashboard: Dashboard) {
    dashboards.value.push(dashboard);
  }

  function createDashboardDefaultConfig() {
    const colNum = 12;
    const rowNum = 12;
    const margin = 4;
    const { colWidth, rowHeight } = getDashboardCellInfo(
      dashboardSize.value,
      colNum,
      rowNum,
      margin
    );
    return {
      colNum,
      rowNum,
      colWidth,
      rowHeight,
      isDraggable: true,
      isResizable: true,
      isMirrored: false,
      isVerticalCompact: true,
      margin,
      isCssTransformsUsed: true,
    };
  }

  function getNonEmptyDashboards() {
    return dashboards.value.filter((dashboard) => dashboard.items.length > 0);
  }

  function getInteractiveRelationships(dashboardId) {
    const result: any[] = [];
    const dashboard = findDashboardById(dashboardId);
    if (!dashboard) {
      return [];
    }
    for (let item of dashboard.items) {
      const eventSourceIds = item.options?.info?.eventSourceIds;
      if (!eventSourceIds) {
        return;
      }
      for (let eventSourceId of eventSourceIds) {
        const eventSource = findDashboardItemById(dashboardId, eventSourceId);
        if (!eventSource) {
          continue;
        }
        result.push({
          eventSource: {
            id: eventSource.id,
            title: eventSource.options?.info?.title,
          },
          observer: {
            id: item.id,
            title: item.options?.info?.title,
          },
        });
      }
    }
    result.sort((r1, r2) => {
      return r1.eventSource.id - r2.eventSource.id;
    });
    return result;
  }

  function setToolContentMaxHeight(newHeight: number) {
    toolContentMaxHeight.value = newHeight;
  }

  async function loadTemplateDetail(templateId) {
    const {
      data: { template },
    } = await axios.get(`gather/template/${templateId}`, {
      params: {
        filters: {
          sampleFilterType: 'all-samples',
          specificSampleIds: [],
          fieldFilterType: 'all-fields',
          specificFieldIds: [],
          fieldValueFilters: [],
        },
      },
    });
    templateDetails.value = {
      ...templateDetails.value,
      [templateId]: { template },
    };
  }

  async function loadTemplateSamples(templateId) {
    const { data } = await axios.get(
      `/data-insights/templates/${templateId}/samples`
    );
    templateSamples.value = {
      ...templateSamples.value,
      [templateId]: data,
    };
  }

  return {
    tools,
    swatches,
    highlightColor,
    dashboards,
    dashboardAction,
    apps,
    loadingDashboards,
    toolContentMaxHeight,
    templateSamples,
    templateDetails,
    loadTemplateDetail,
    loadTemplateSamples,
    loadDashboards,
    loadApps,
    findDashboardById,
    findDashboardItemById,
    createDashboardDefaultConfig,
    setAutoRefreshIntervalId,
    setSelectedDashboardItemId,
    setSelectedDashboardId: (dashboardId: number | null) => {
      selectedDashboardId.value = dashboardId;
    },
    setDashboardSize,
    setDashboardAction,
    addDashboard,
    addDashboardItem,
    saveDashboardItem,
    saveDashboard,
    replaceDashboard,
    deleteDashboard,
    deleteDashboardItem,
    updateDashboardItem,
    updateDashboardRuntimeData,
    updateDashboardItemOptions,
    getDashboardItemStatuses,
    getDashboardLayout,
    getAutoRefreshIntervalId: (dashboardId: number) =>
      dashboardId in autoRefreshIntervalIds.value
        ? autoRefreshIntervalIds.value[dashboardId]
        : null,
    getObservers,
    getNonEmptyDashboards,
    getInteractiveRelationships,
    isDashboardItemBeingEdited,
    takeSnapshotOfSelectedDashboard,
    saveSelectedDashboardItem,
    cancelSelectedDashboardItem,
    findTool,
    setToolContentMaxHeight,

    selectedDashboardId,
    selectedDashboard,
    selectedDashboardItem,

    currentTool: computed(() => {
      return selectedDashboardItem.value
        ? findTool(selectedDashboardItem.value.toolType)
        : null;
    }),

    getNextVacantDashboardItemLayout: (dashboard: Dashboard) => {
      const dashboardLayout = getDashboardLayout(dashboard);
      const {
        config: { colNum, rowNum },
      } = dashboard;
      return getNextVacantDashboardItemLayout(
        dashboardLayout,
        colNum,
        rowNum,
        4,
        4
      );
    },

    updateViewMode: async ({ dashboardItemId, viewModeId }) => {
      await axios.post(
        `/data-insights/dashboard-item/${dashboardItemId}/update-view-mode/${viewModeId}`
      );
    },
  };
});
