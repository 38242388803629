import api from '../api/index.js';

const setProcessedInformation = (context, data) => {
  context.commit('SET_PROCESSED_INFORMATION', data);
};

const setProcessedInformationField = (context, data) => {
  context.commit('SET_PROCESSED_INFORMATION_FIELD', data);
};

const setProcessedSettingsField = (context, data) => {
  context.commit('SET_PROCESSED_SETTINGS_FIELD', data);
};

const updateProcessedGroupField = (context, data) => {
  context.commit('SET_PROCESSED_GROUP_FIELD', data);
};

const updateScenarioGroupFilters = async ({ commit }, { group_id, data }) => {
  await api.updateScenarioGroup(group_id, data);

  commit('SET_PROCESSED_GROUP_FIELD', {
    group_id: group_id,
    key: 'filters',
    value: data.filters,
  });
};

const updateScenarioGroup = (context, data) => {
  context.commit('UPDATE_SCENARIO_GROUP', data);
};

const createScenarios = (context, scenarios) => {
  context.commit('CREATE_SCENARIOS', scenarios);
};

const deleteScenario = (context, id) => {
  context.commit('DELETE_SCENARIO', id);
};

const updateScenario = (context, scenario) => {
  context.commit('UPDATE_SCENARIO', scenario);
};

const setAllOutdatedStatistics = async (context) => {
  const scenarioGroup = context.rootGetters['dashboard/get_scenario_group'];
  if (
    !scenarioGroup?.has_statistics ||
    scenarioGroup?.has_outdated_statistics
  ) {
    return;
  }

  try {
    await api.setAllOutdatedStatistics();

    context.commit('SET_ALL_OUTDATED_STATISTICS');
  } catch (e) {
    throw e;
  }
};

const setAllFigureLayersOutdated = async (context) => {
  const scenarioGroup = context.rootGetters['dashboard/get_scenario_group'];
  if (
    !scenarioGroup?.has_layer_filtering ||
    scenarioGroup?.has_outdated_layer_filtering
  ) {
    return;
  }
  try {
    await api.setAllFigureLayersOutdated();
    context.commit('SET_ALL_FIGURE_LAYERS_OUTDATED');
  } catch (e) {
    throw e;
  }
};

const updateScenarioOrdering = async (context, scenarios) => {
  context.commit('UPDATE_SCENARIO_ORDERING', scenarios);
};

export default {
  setProcessedInformation,
  setProcessedInformationField,
  setProcessedSettingsField,
  updateProcessedGroupField,
  updateScenarioGroupFilters,
  updateScenarioGroup,
  createScenarios,
  deleteScenario,
  updateScenario,
  setAllOutdatedStatistics,
  setAllFigureLayersOutdated,
  updateScenarioOrdering,
};
