import { EditableBlockType } from '@component-library/enviro-table-footer-blocks';

export type Variable = {
  key: VariableOption;
  title: string;
  tooltip: string;
  prepend?: string;
};

export enum VariableOption {
  DOCUMENT_SHORT = 'document_short',
  DOCUMENT_LONG = 'document_long',
  SCENARIO_TITLE = 'scenario_title',
  IDENTIFER = 'identifier',
  TABLE_REFERENCES = 'table_references',
  CUSTOM_CRITERIA_IDENTIFER = 'custom_criteria_identifier',
  CUSTOM_CRITERIA_COMMENT = 'custom_criteria_comment',
}

export const variables: Array<Variable> = [
  {
    key: VariableOption.DOCUMENT_SHORT,
    title: 'Standard Short Name',
    tooltip: 'e.g. Mfe NES',
  },
  {
    key: VariableOption.DOCUMENT_LONG,
    title: 'Standard Long Name',
    tooltip:
      'e.g. Methodology for Deriving Soil Guideline Values Protective of Human Health',
  },
  {
    key: VariableOption.SCENARIO_TITLE,
    title: 'Scenario',
    tooltip: 'e.g. Human Health, Residential',
  },
  {
    key: VariableOption.IDENTIFER,
    title: 'Identifier',
    tooltip: '1., 2., 3., etc.',
  },
  {
    key: VariableOption.TABLE_REFERENCES,
    title: 'Tables',
    tooltip: 'e.g. Table 1, Table 2, Table 3, etc.',
    prepend: 'from table',
  },
  {
    key: VariableOption.CUSTOM_CRITERIA_IDENTIFER,
    title: 'Identifier',
    tooltip: '1., 2., 3., etc.',
  },
  {
    key: VariableOption.CUSTOM_CRITERIA_COMMENT,
    title: 'Comment',
    tooltip: 'Custom comment set by criteria set.',
  },
];

export const getVariables = (type: EditableBlockType): Array<Variable> => {
  switch (type) {
    case EditableBlockType.SCENARIOS:
      return variables.filter((v) =>
        [
          VariableOption.DOCUMENT_SHORT,
          VariableOption.DOCUMENT_LONG,
          VariableOption.SCENARIO_TITLE,
        ].includes(v.key)
      );
    case EditableBlockType.ADOPTED_CRITERIA:
      return variables.filter((v) =>
        [
          VariableOption.IDENTIFER,
          VariableOption.DOCUMENT_SHORT,
          VariableOption.DOCUMENT_LONG,
          VariableOption.SCENARIO_TITLE,
          VariableOption.TABLE_REFERENCES,
        ].includes(v.key)
      );
    case EditableBlockType.CRITERIA_COMMENTS:
      return variables.filter((v) =>
        [
          VariableOption.CUSTOM_CRITERIA_IDENTIFER,
          VariableOption.SCENARIO_TITLE,
          VariableOption.CUSTOM_CRITERIA_COMMENT,
        ].includes(v.key)
      );
  }

  return [];
};
