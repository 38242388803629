import { checkIfResultIsAboveGuideline } from '../helpers/guideline-export.js';
import { convertValueByUnits } from '@/js/helpers/result.js';
import { getDefaultState } from './index.js';
import { markLastInGroup } from '../helpers/chemical-utils.ts';
import { getDefaultBlocks } from '../components/table/editable-footer/types/block-editor';
import { EditableBlockType } from '@component-library/enviro-table-footer-blocks';

const RESET_STATE = (state) => {
  const s = getDefaultState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};

const LOAD_PAGINATED_SAMPLES_PENDING = (state) => {
  state.load_paginated_samples_pending = true;
  state.load_all_data_error = false;
};

const LOAD_PAGINATED_SAMPLES_COMPLETE = (
  state,
  { samples, flattened_samples, number_of_items }
) => {
  state.load_paginated_samples_pending = false;

  state.load_paginated_samples_complete = {
    ...samples,
    number_of_items:
      number_of_items || state.load_paginated_samples_complete?.number_of_items,
  };
  state.load_all_paginated_samples = Object.freeze(flattened_samples);
};

const RESET_ALL_SAMPLES_DATA = (state) => {
  state.load_all_paginated_samples = [];
  state.load_paginated_samples_complete = null;
};

const LOAD_CHEMICALS_PENDING = (state) => {
  state.load_chemicals_pending = true;
  state.load_all_data_error = false;
};

const LOAD_CHEMICALS_COMPLETE = (
  state,
  { chemicals, flattened_chemicals, number_of_items }
) => {
  state.load_chemicals_pending = false;

  state.load_paginated_chemicals_complete = {
    ...chemicals,
    number_of_items:
      number_of_items ||
      state.load_paginated_chemicals_complete?.number_of_items,
  };

  state.load_chemicals_complete = Object.freeze(
    markLastInGroup(flattened_chemicals)
  );

  if (flattened_chemicals.length == 0) {
    state.loaded_results = true;
  }
};

const RESET_ALL_CHEMICALS_DATA = (state) => {
  state.load_paginated_chemicals_complete = null;
  state.load_chemicals_complete = [];
};

const LOAD_RESULTS_PENDING = (state) => {
  state.load_results_pending = true;
};

const LOAD_RESULTS_COMPLETE = (state, results) => {
  state.load_results_pending = false;
  state.load_results_complete = Object.freeze(results);
  state.loaded_results = true;
};

const RESET_RESULTS_DATA = (state) => {
  if (!state.exporting) {
    state.load_results_complete = [];
  }
  state.loaded_results = false;
};

const LOAD_GATHER_VALUES_COMPLETE = (state, values) => {
  state.load_gather_values_complete = Object.freeze(values);
};

const RESET_EXPORT_DATA = (state) => {
  if (state.scenario_set?.is_horizontal) {
    state.load_all_paginated_samples = [];
  } else {
    state.load_chemicals_complete = [];
  }

  state.load_results_complete = [];
  state.loaded_results = false;
};

const SET_LOADED_RESULTS = (state) => {
  state.loaded_results = true;
};

const RESET_ALL_TABLE_DATA = (state) => {
  state.load_paginated_samples_complete = null;
  state.load_all_paginated_samples = [];

  state.load_paginated_chemicals_complete = null;
  state.load_chemicals_complete = [];

  state.load_results_complete = [];
  state.loaded_results = false;
};

const LOAD_ADDITIONAL_DATA_PENDING = (state) => {
  state.load_additional_data_pending = true;
};

const LOAD_ADDITIONAL_DATA_COMPLETE = (
  state,
  {
    chemical_groups,
    sample_notes,
    settings,
    total_number_of_samples,
    total_number_of_chemicals,
  }
) => {
  state.load_additional_data_pending = false;
  state.all_chemical_groups = chemical_groups;
  state.sample_notes = Object.freeze(sample_notes);
  state.table_settings = settings;
  state.total_number_of_samples = total_number_of_samples;
  state.total_number_of_chemicals = total_number_of_chemicals;
};

const SET_TABLE_SETTINGS_FIELD = (state, { key, value }) => {
  state.table_settings[key] = value;
};

const SET_IS_EXPORT_PREVIEW = (state) => {
  state.is_export_preview = true;
};

const SET_IS_EXPORTING = (state, isExporting) => {
  state.is_exporting = isExporting;
};

const SET_EXPORT_SETTINGS = (state, settings) => {
  const defaultBlocks = getDefaultBlocks(state.scenario_set);
  const visibleBlocks = Object.keys(defaultBlocks)
    .filter((key) => defaultBlocks[key].is_visible)
    .map((key) => parseInt(key));

  let identifier = 1;

  if (
    visibleBlocks.includes(EditableBlockType.ADOPTED_CRITERIA) &&
    (!state.scenario_set ||
      state.scenario_set.table_style_key !== 'momentum-styling')
  ) {
    settings.documents_used = settings.documents_used.map((document) => {
      return {
        ...document,
        scenario_table_references: document.scenario_table_references.map(
          (reference) => {
            return {
              ...reference,
              identifier: identifier++,
            };
          }
        ),
      };
    });
  }

  if (visibleBlocks.includes(EditableBlockType.CRITERIA_COMMENTS)) {
    settings.custom_criteria_comments = settings.custom_criteria_comments.map(
      (comment) => {
        return {
          ...comment,
          identifier: identifier++,
        };
      }
    );
  }

  if (visibleBlocks.includes(EditableBlockType.CRITERIA_ANALYTES)) {
    settings.custom_criteria_analyte_comments =
      settings.custom_criteria_analyte_comments.map((analyte) => {
        return {
          ...analyte,
          identifier: identifier++,
        };
      });
  }

  if (visibleBlocks.includes(EditableBlockType.GUIDELINE_CHANGE)) {
    settings.guideline_change_reasons = settings.guideline_change_reasons.map(
      (reason) => {
        return {
          ...reason,
          identifier: identifier++,
        };
      }
    );
  }

  if (visibleBlocks.includes(EditableBlockType.GUIDELINE_NOTE)) {
    settings.guideline_comments = settings.guideline_comments.map((note) => {
      return {
        ...note,
        identifier: identifier++,
      };
    });
  }

  if (visibleBlocks.includes(EditableBlockType.LAB_REPORT_NUMBER)) {
    settings.lab_report_numbers = settings.lab_report_numbers.map(
      (lab_report_number) => {
        return {
          lab_report_number,
          identifier: identifier++,
        };
      }
    );
  }

  state.export_settings = settings;
};

const SET_IS_LOADING_EXPORT_SETTINGS = (state, isLoading) => {
  state.is_loading_export_settings = isLoading;
};

const SET_SAMPLE_FIELDS = (state, fields) => {
  state.sample_fields = fields;
};

const SET_CHEMICAL_FIELDS = (state, fields) => {
  state.chemical_fields = fields;
};

const LOAD_ALL_DATA_ERROR = (state) => {
  state.load_chemicals_pending = false;
  state.load_paginated_samples_pending = false;
  state.load_additional_data_pending = false;
  state.load_all_data_error = true;
};

const CONFIGURE_FILTERS = (state, filters) => {
  state.filters = { ...state.filters, ...filters };
};

const UPDATE_APPLY_FILTER_STATUS = (state, status) => {
  state.updating_filters = status;
};

const MODIFY_CHEMICAL_GROUP = (state, group) => {
  const existingGroupIndex = state.all_chemical_groups.findIndex(
    (g) => g.id == group.id
  );

  if (existingGroupIndex == -1) {
    state.all_chemical_groups.unshift(group);
    return;
  }

  Object.keys(group).forEach((key) => {
    state.all_chemical_groups[existingGroupIndex][key] = group[key];
  });
};

const DELETE_CHEMICAL_GROUP = (state, id) => {
  const existingGroupIndex = state.all_chemical_groups.findIndex(
    (g) => g.id == id
  );

  if (existingGroupIndex != -1) {
    state.all_chemical_groups.splice(existingGroupIndex, 1);
  }
};

const LOAD_CHEMICAL_UNITS_PENDING = (state) => {
  state.load_chemical_units_pending = true;
  state.load_chemical_units_complete = [];
};

const LOAD_CHEMICAL_UNITS_COMPLETE = (state, { chemical_units }) => {
  state.load_chemical_units_pending = false;
  state.load_chemical_units_complete = Object.freeze(chemical_units);
};

const LOAD_CHEMICAL_UNITS_ERROR = (state) => {
  state.load_chemical_units_pending = false;
  state.load_chemical_units_complete = [];
};

const SET_EDITING_DATA = (state, data) => {
  state.editing_data = data;
};

const SET_TABLE_ONLY_VIEW = (state, tableOnlyView) => {
  state.table_only_view = tableOnlyView;
};

const MODIFY_SAMPLE = (state, sample) => {
  let storeSample = state.load_all_paginated_samples.find(
    (s) => s.id == sample.id
  );

  if (storeSample) {
    Object.keys(sample).forEach((key) => {
      storeSample[key] = sample[key];
    });
  }
};

const DELETE_SAMPLE = (state, id) => {
  state.load_all_paginated_samples = Object.freeze(
    state.load_all_paginated_samples.filter((s) => s.id != id)
  );
};

const ABSORB_SAMPLE = (state, { sample_id, absorb_id }) => {
  let sample = state.load_all_paginated_samples.find((s) => s.id == sample_id);

  const absorbSampleIndex = state.load_all_paginated_samples.findIndex(
    (c) => c.id == absorb_id
  );

  if (absorbSampleIndex != -1) {
    // absorb sample properties
    let sampleToAbsorb = state.load_all_paginated_samples[absorbSampleIndex];
    [
      'start_depth',
      'end_depth',
      'latitude',
      'longitude',
      'sampled_date',
    ].forEach((key) => {
      if (!sample[key]) {
        sample[key] = sampleToAbsorb[key];
      }
    });

    // remove sample from table
    state.load_all_paginated_samples = Object.freeze(
      state.load_all_paginated_samples.filter((s) => s.id != absorb_id)
    );
  }

  // move sample reuslts to new sample
  state.load_results_complete
    .filter((s) => s.sample_id == absorb_id && s.chemical_id == chemical.id)
    .forEach((result) => {
      result.sample_id = sample_id;
    });
};

const MODIFY_RESULT = (state, result) => {
  state.load_results_complete = Object.freeze([
    ...state.load_results_complete.filter((r) => r.id != result.id),
    result,
  ]);
};

const DELETE_RESULT = (state, id) => {
  state.load_results_complete = Object.freeze(
    state.load_results_complete.filter((r) => r.id != id)
  );
};

const MODIFY_RESULT_NOTE = (state, note) => {
  let existingNote = state.sample_notes.find((n) => n.id == note.id);

  if (existingNote) {
    Object.keys(note).forEach((key) => {
      existingNote[key] = note[key];
    });
    return;
  }

  state.sample_notes = Object.freeze([...state.sample_notes, note]);
};

const DELETE_RESULT_NOTE = (state, id) => {
  state.sample_notes = Object.freeze(
    state.sample_notes.filter((n) => n.id != id)
  );
};

const MODIFY_CHEMICAL = (state, chemical) => {
  let sampleChemical = state.load_chemicals_complete.find(
    (c) => c.id == chemical.id
  );

  if (chemical.units && sampleChemical.units != chemical.units) {
    state.load_results_complete
      .filter((r) => r.chemical_id == sampleChemical.id)
      .forEach((result) => {
        const convertedResult = convertValueByUnits(
          result.display_result,
          sampleChemical.units,
          chemical.units
        );

        result.display_result = convertedResult;
        result.result = convertedResult;
        result.changed_result_value = null;
      });

    if (chemical.eql_result) {
      chemical.eql_result = convertValueByUnits(
        chemical.eql_result,
        sampleChemical.units,
        chemical.units
      );
    }
  }

  Object.keys(chemical).forEach((key) => {
    sampleChemical[key] = chemical[key];
  });
};

const DELETE_CHEMICAL = (state, id) => {
  state.load_chemicals_complete = Object.freeze(
    state.load_chemicals_complete.filter((s) => s.id != id)
  );
};

const SET_HYDROCARBON_EXPORT = (state, exportType) => {
  state.is_hydrocarbon_export = exportType == 'only-hydrocarbons';
  state.hydrocarbon_export_type = exportType;
};

const UPDATE_GUIDELINE = (
  state,
  { chemical, guideline, newItemGuideline, byCell }
) => {
  const sampleChemical = state.load_chemicals_complete.find(
    (c) => c.id == chemical.id
  );

  sampleChemical.item_guidelines = [
    ...sampleChemical.item_guidelines.filter((itemGuideline) => {
      return (
        itemGuideline.scenario_id != guideline.scenario_id ||
        (itemGuideline.scenario_id == guideline.scenario_id &&
          (byCell
            ? !itemGuideline.item_id ||
              (itemGuideline.item_id &&
                itemGuideline.item_id != guideline.item_id)
            : false))
      );
    }),
    guideline,
  ];

  state.load_results_complete
    .filter(
      (result) =>
        result.chemical_id == sampleChemical.id &&
        (guideline.item_id ? result.id == guideline.item_id : true)
    )
    .forEach((result) => {
      const exceeds = checkIfResultIsAboveGuideline(
        state.load_all_paginated_samples,
        result,
        guideline
      );

      newItemGuideline = {
        ...newItemGuideline,
        item_id: result.id,
        chemical_id: result.chemical_id,
        sample_id: result.sample_id,
        exceeds,
      };

      result.sample_guidelines = [
        ...result.sample_guidelines.filter(
          (g) => g.scenario_id != guideline.scenario_id || !g.item_id
        ),
        newItemGuideline,
      ];
    });
};

const CREATE_SAMPLE_WITH_RESULTS = (state, sample) => {
  state.load_results_complete = Object.freeze(
    state.load_results_complete.concat(sample.sample_items)
  );

  delete sample.sample_items;

  state.load_all_paginated_samples = Object.freeze([
    ...state.load_all_paginated_samples,
    sample,
  ]);
};

const TOGGLE_SAMPLE_RPD = (state, sample) => {
  const sampleIndex = state.load_all_paginated_samples.findIndex(
    (c) => c.id == sample.id
  );

  const samples = JSON.parse(JSON.stringify(state.load_all_paginated_samples));

  samples[sampleIndex] = {
    ...sample,
    show_specific_rpd:
      !sample.show_specific_rpd || sample.show_specific_rpd === 'duplicate'
        ? 'triplicate'
        : 'duplicate',
  };

  state.load_all_paginated_samples = Object.freeze(samples);
};

const UPDATE_CHEMICAL_RPD_ACCEPTANCE = (
  state,
  { chemical_id, acceptance_value }
) => {
  const chemical = state.load_chemicals_complete.find(
    (c) => c.id == chemical_id
  );

  chemical.rpd_acceptance = acceptance_value;
};

export default {
  RESET_STATE,

  LOAD_PAGINATED_SAMPLES_PENDING,
  LOAD_PAGINATED_SAMPLES_COMPLETE,
  RESET_ALL_SAMPLES_DATA,

  LOAD_CHEMICALS_PENDING,
  LOAD_CHEMICALS_COMPLETE,
  RESET_ALL_CHEMICALS_DATA,

  LOAD_RESULTS_PENDING,
  LOAD_RESULTS_COMPLETE,
  RESET_RESULTS_DATA,

  LOAD_GATHER_VALUES_COMPLETE,

  RESET_EXPORT_DATA,

  SET_LOADED_RESULTS,
  RESET_ALL_TABLE_DATA,

  LOAD_ALL_DATA_ERROR,

  LOAD_ADDITIONAL_DATA_PENDING,
  LOAD_ADDITIONAL_DATA_COMPLETE,
  SET_TABLE_SETTINGS_FIELD,
  SET_IS_EXPORT_PREVIEW,
  SET_IS_EXPORTING,
  SET_EXPORT_SETTINGS,
  SET_IS_LOADING_EXPORT_SETTINGS,
  SET_SAMPLE_FIELDS,
  SET_CHEMICAL_FIELDS,
  CONFIGURE_FILTERS,
  UPDATE_APPLY_FILTER_STATUS,

  MODIFY_CHEMICAL_GROUP,
  DELETE_CHEMICAL_GROUP,

  LOAD_CHEMICAL_UNITS_PENDING,
  LOAD_CHEMICAL_UNITS_COMPLETE,
  LOAD_CHEMICAL_UNITS_ERROR,

  SET_EDITING_DATA,
  SET_TABLE_ONLY_VIEW,

  MODIFY_SAMPLE,
  DELETE_SAMPLE,
  ABSORB_SAMPLE,
  MODIFY_RESULT,
  DELETE_RESULT,
  MODIFY_RESULT_NOTE,
  DELETE_RESULT_NOTE,
  MODIFY_CHEMICAL,
  DELETE_CHEMICAL,

  SET_HYDROCARBON_EXPORT,

  UPDATE_GUIDELINE,
  CREATE_SAMPLE_WITH_RESULTS,
  TOGGLE_SAMPLE_RPD,

  UPDATE_CHEMICAL_RPD_ACCEPTANCE,
};
