import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import { distance } from 'ol/coordinate';
import { containsCoordinate } from 'ol/extent';
import Feature from 'ol/Feature';
import { LineString } from 'ol/geom';
import { BLACK, WHITE } from '../../../../business-logic/color';
import { LAYER_TYPES } from '../../../../business-logic/layer';
import PopupHandler from '../../../PopupHandler';
import * as utils__ from '../../utils';
import * as constants from './constants';

export function findNearestSample(map, position) {
  const viewer = map.getViewer();
  const { allVisibleSamples, allVisibleGatherSamples } = viewer;
  const extent = map.getView().calculateExtent();
  const samples = _uniq([
    ...allVisibleSamples,
    ...allVisibleGatherSamples,
  ]).filter((item) => {
    const samplePosition = utils__.getSampleCoordinate(map, item);
    return containsCoordinate(extent, samplePosition);
  });

  const positionPx = map.getPixelFromCoordinate(position);
  const sampleDistances = samples
    .map((item) => {
      const samplePosition = utils__.getSampleCoordinate(map, item);
      const samplePositionPx = map.getPixelFromCoordinate(samplePosition);
      return {
        item,
        distance: distance(positionPx, samplePositionPx),
      };
    })
    .filter((item) => item.distance <= 10)
    .sort((a, b) => a.distance - b.distance);
  return sampleDistances.length ? sampleDistances[0].item : null;
}

/**
 * Return the connector and options of a builtin call-out.
 * @param {ol.Map} map
 * @param {Object} sample
 * @param {String} contentType
 * @returns
 */
export function getBuiltinCalloutConfig(
  map,
  sample,
  contentType = constants.BUILTIN_CALLOUT_CONTENT_TYPES.ENVIRO
) {
  const viewer = map.getViewer();
  const {
    selectedFigure: {
      popup_font_size: fontSize,
      popup_offsets,
      builtin_callouts_by_sample: builtinCalloutsBySample,
    },
  } = viewer;
  const positions = popup_offsets || [];
  const builtinCallouts = builtinCalloutsBySample[sample.id] ?? [];
  const builtinCallout = builtinCallouts.find(
    (bc) => bc.geojson.properties.contentType === contentType
  );

  if (builtinCallout) {
    return {
      connector: utils__.geojson.fromGeoJSON(
        map,
        _omit(builtinCallout.geojson, ['properties'])
      )[0],
      options: viewer.getScaledLayerProperties(
        builtinCallout.geojson.properties
      ),
    };
  } else {
    let startPosition;
    const position = positions.find((p) => p.sample_id === sample.id);
    if (position) {
      const projection = map.getView().getProjection();
      startPosition = utils__.coordinate.latLngToCoordinate(
        position,
        projection
      );
    } else {
      startPosition = utils__.getSampleCoordinate(map, sample);
    }

    const endPosition = utils__.getSampleCoordinate(map, sample);
    return {
      connector: new Feature(new LineString([startPosition, endPosition])),
      options: viewer.getScaledLayerProperties({
        type: LAYER_TYPES.CALL_OUT,
        positioning: utils__.position.calculateOverlayPositioning(
          map,
          startPosition,
          endPosition
        ),
        connectedTarget: {
          type: constants.CONNECTED_TARGET_TYPES.SAMPLE,
          id: sample.id,
        },
        // The content of builtin call-out is not the text.
        text: '',
        color: BLACK,
        backgroundColor: WHITE,
        weight: constants.DEFAULT_WEIGHT,
        opacity: 1,
        width: constants.DEFAULT_WIDTH,
        fontSize,
        isBuiltin: true,
        contentType,
      }),
    };
  }
}

export function getEnviroTable(map, sample, style) {
  const { project, selectedFigure, allChemicalGroups } = map.getViewer();
  const popupHandler = new PopupHandler(
    project,
    selectedFigure,
    sample,
    allChemicalGroups
  );
  return popupHandler.getPopupTable(style);
}

export function getGatherTable(
  map,
  sample,
  style = {
    textColor: BLACK,
    textShadow: 'none',
    fontWeight: 'normal',
  }
) {
  const { gather_callout_data } = sample;
  if (_isEmpty(gather_callout_data)) {
    return null;
  }

  const {
    selectedFigure: {
      gather_callout_settings: { isSectionHeaderVisibleByTab = {} },
    },
  } = map.getViewer();

  const { textColor, textShadow, fontWeight } = style;
  const dataCellStyle = `color: ${textColor}; text-shadow: ${textShadow}; font-weight: ${fontWeight}; background-color: transparent;`;

  const rows = [];
  let previousSectionId;
  for (let i = 0; i < gather_callout_data.length; i++) {
    const { label, value, templateTabId, sectionId, sectionLabel } =
      gather_callout_data[i];
    if (
      isSectionHeaderVisibleByTab[templateTabId] &&
      sectionId !== previousSectionId
    ) {
      // Section header row
      rows.push(
        `<tr class="bg-primary" style="color: ${WHITE}; text-shadow: none;
        text-align: left; font-style: normal; font-weight: normal;
        textDecoration: none;">
        <td colspan="2">${sectionLabel}</td></tr>`
      );
      previousSectionId = sectionId;
    }
    rows.push(
      `<tr><td style="${dataCellStyle}">${label}</td><td style="${dataCellStyle}">${
        Array.isArray(value) ? value.join('<br/>') : value
      }</td></tr>`
    );
  }

  return `<table class="table table-bordered builtin-callout-table">
    <tbody>${rows.join('')}</tbody>
    </table>`;
}
