import EventBus from '@component-library/EventBus';
import { getShapeProperty as _getShapeProperty } from '@component-library/business-logic/mapping/styling-rule';
import type { ShapeProperties } from '@component-library/components/mapping/style-editors/context';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import { computed, watch } from 'vue';
import { LAYER_TYPES } from '../business-logic/layer';
import { getUsage } from '../business-logic/tool/call-out';
import { StylingPriority } from '../lib/olbm/style/types';
import { dispatch, getGetter, getState } from '../view-utils';

export default function useShapeProperties() {
  const shapeProperties = computed(() => {
    return getState('shapeProperties');
  });

  const getShapeProperty = (name: string, defaultValue: any = null) => {
    return _getShapeProperty(shapeProperties.value, name, defaultValue);
  };

  const setShapeProperty = async (
    name: string,
    value
  ): Promise<ShapeProperties> => {
    const currentValue = getShapeProperty(name);
    if (_isEqual(currentValue, value)) {
      return shapeProperties.value;
    }
    return await updateShapeProperties({ [name]: value });
  };

  const updateShapeProperties = async (update): Promise<ShapeProperties> => {
    const { stylingPriority = StylingPriority.Custom } = update;
    await dispatch('setShapeProperty', {
      ...update,
      stylingPriority,
    });
    return _cloneDeep(shapeProperties.value);
  };

  const replaceShapeProperties = async (value): Promise<void> => {
    await dispatch('setCurrentShapeProperties', value);
  };

  const layerType = computed(() => getShapeProperty('type'));
  const layerUsage = computed(() => getShapeProperty('usage'));
  const isForServiceLayer = computed(() =>
    getShapeProperty('isForServiceLayer')
  );
  const isServiceLayerFolderLayer = computed(() =>
    getGetter('isServiceLayerFolderLayer')(shapeProperties.value)
  );
  const connectedTarget = computed(() => getShapeProperty('connectedTarget'));
  const isBuiltin = computed(() => getShapeProperty('isBuiltin', false));
  const imageTransparency = computed({
    get() {
      return parseFloat(getShapeProperty('image_data')?.transparency ?? 1);
    },
    set(value) {
      const imageData = getShapeProperty('image_data');
      setShapeProperty('image_data', {
        ...imageData,
        transparency: value,
      });

      EventBus.$emit('setImageLayerOpacity', value);
    },
  });
  const layerGroupOpacity = computed({
    get() {
      return getShapeProperty('groupOpacity', 1);
    },
    set(value) {
      setShapeProperty('groupOpacity', value);

      EventBus.$emit('setGroupLayerOpacity', {
        layer: null,
        opacity: value,
      });
    },
  });
  const isBuiltinCallout = computed(() => {
    return layerType.value === LAYER_TYPES.CALL_OUT && isBuiltin.value;
  });
  const builtinCalloutSharedProperties = computed(() => {
    return _pick(shapeProperties.value, [
      'textColor',
      'textOutlineColor',
      'fontSize',
      'alignment',
      'isItalic',
      'isBold',
      'isUnderlined',
      'color',
      'backgroundColor',
      'width',
      'height',
      'opacity',
      'weight',
    ]);
  });
  const isEmpty = computed(() => _isEmpty(shapeProperties.value));
  const isContours = computed<boolean>(() => {
    return getGetter('isContours');
  });
  const selectedContourId = computed<number | undefined>(() => {
    return getGetter('selectedContourId');
  });

  const calloutUsage = computed(() => {
    return getUsage(shapeProperties.value);
  });

  watch(shapeProperties, (newValue, oldValue) => {
    // The change happens in the Styling Rule editor.
    if (!layerType.value) {
      return;
    }

    if (
      layerType.value === LAYER_TYPES.SITE_BOUNDARY &&
      !newValue.isMyOwnStyleUsed
    ) {
      EventBus.$emit('shareStyleAmongAllSiteBoundaries', {
        shapeProperties: newValue,
        shouldRevertToCommon: !!oldValue.isMyOwnStyleUsed,
      });
      return;
    }

    EventBus.$emit('changeStyleOption', newValue);
  });

  return {
    layerType,
    layerUsage,
    isForServiceLayer,
    isServiceLayerFolderLayer,
    connectedTarget,
    isBuiltin,
    isBuiltinCallout,
    builtinCalloutSharedProperties,
    imageTransparency,
    layerGroupOpacity,
    isEmpty,
    isContours,
    selectedContourId,
    calloutUsage,
    getShapeProperty,
    setShapeProperty,
    updateShapeProperties,
    replaceShapeProperties,
  };
}
