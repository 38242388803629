<template>
  <div class="editor__bar">
    <template
      v-if="
        (isSlideBlock && getSlideFigureType(block) == 'map') ||
        block.type == 'map'
      "
    >
      <button
        class="btn btn-light btn-sm me-1"
        title="Add actions"
        @click="setActionManagement(block)"
      >
        <i class="fal fa-bolt" />
      </button>

      <div class="divider me-1" />
    </template>

    <template v-if="block.type == 'attachment'">
      <button
        class="btn btn-light btn-sm me-1"
        title="Add attachments"
        @click="setBlockAttachmentManagement(block.id)"
      >
        <i class="fal fa-upload" />
      </button>

      <div class="divider me-1" />
    </template>

    <template v-if="hasTool('media')">
      <button
        class="btn btn-light btn-sm me-1"
        title="Upload media"
        @click="$emit('setMediaManagement')"
      >
        <i class="fal fa-upload" />
      </button>

      <div class="divider me-1" />
    </template>

    <template v-if="hasTool('floats') && !isSlideBlock">
      <button
        class="btn btn-light btn-sm me-1"
        title="Float left"
        :class="{
          active: block.float == floats.left,
        }"
        @click="updateBlockFloat(1)"
      >
        <i class="fal fa-align-left" />
      </button>

      <button
        class="btn btn-light btn-sm me-1"
        title="Center"
        :class="{
          active: block.float == floats.none,
        }"
        @click="updateBlockFloat(floats.none)"
      >
        <i class="fal fa-align-center" />
      </button>

      <button
        class="btn btn-light btn-sm me-1"
        title="Float right"
        :class="{
          active: block.float == floats.right,
        }"
        @click="updateBlockFloat(floats.right)"
      >
        <i class="fal fa-align-right" />
      </button>

      <div class="divider me-1" />
    </template>

    <template v-if="hasTool('sizes') && !isSlideBlock && !block.group_block_id">
      <button
        class="btn btn-light btn-sm me-1"
        title="Small media"
        :class="{
          active: block.size == media_sizes.small,
        }"
        @click="updateBlockSize(media_sizes.small)"
      >
        <i class="fal fa-square" />
      </button>

      <button
        class="btn btn-light btn-sm me-1"
        title="Medium media"
        :class="{
          active: block.size == media_sizes.medium,
        }"
        @click="updateBlockSize(media_sizes.medium)"
      >
        <i class="fal fa-rectangle-landscape" />
      </button>

      <button
        class="btn btn-light btn-sm me-1"
        title="Large media"
        :class="{
          active: block.size == media_sizes.large,
        }"
        @click="updateBlockSize(media_sizes.large)"
      >
        <i class="fal fa-rectangle-wide" />
      </button>

      <div class="divider me-1" />
    </template>

    <template v-if="hasTool('padding')">
      <DropdownMenu>
        <template slot="button" slot-scope="btn">
          <button
            class="btn btn-light btn-sm me-1 dropdown-toggle"
            :class="{ toggled: btn.toggled }"
            @click="btn.toggle"
          >
            <i class="fal fa-arrows-alt-v" />
          </button>
        </template>
        <template slot="items" slot-scope="items">
          <div class="py-2">
            <div class="mb-2">
              <label for="bottomPadding" class="form-label">
                Top Padding ({{ block.padding?.top || 0 }}px)
              </label>
              <div class="input-group input-group-sm">
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  :value="block.padding?.top || 0"
                  @change="(e) => updatePadding('top', e.target.value)"
                />
                <span class="input-group-text">px</span>
              </div>
            </div>
            <div>
              <label for="bottomPadding" class="form-label">
                Bottom Padding ({{ block.padding?.bottom || 0 }}px)
              </label>
              <div class="input-group input-group-sm">
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  :value="block.padding?.bottom || 0"
                  @change="(e) => updatePadding('bottom', e.target.value)"
                />
                <span class="input-group-text">px</span>
              </div>
            </div>
          </div>
        </template>
      </DropdownMenu>

      <div class="divider me-1" />
    </template>

    <button
      v-if="hasTool('duplicate')"
      class="btn btn-light btn-sm me-1"
      title="Duplicate"
      @click="$emit('duplicate')"
    >
      <i class="fal fa-copy" />
    </button>

    <button
      v-if="
        hasTool('settings') ||
        (hasTool('figure-settings') && block.is_slide_figure)
      "
      class="btn btn-light btn-sm me-1"
      title="Settings"
      @click="$emit('setBlockManagement')"
    >
      <i class="fal fa-cog" />
    </button>

    <div class="divider me-1" />

    <button
      v-if="hasTool('delete')"
      class="btn btn-danger btn-sm"
      title="Delete"
      @click="$emit('delete')"
    >
      <i class="fal fa-trash-alt" />
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import constants from '../../../helpers/constants.js';
import DropdownMenu from '@/js/components/DropdownMenu.vue';

export default {
  props: {
    block: Object,
    tools: {
      type: Array,
      default: () => {
        return ['delete'];
      },
    },
  },
  mixins: [constants],
  components: {
    DropdownMenu,
  },
  computed: {
    ...mapGetters('reporter', ['getSlideFigureType']),
    isSlideBlock() {
      return this.block.slide_id;
    },
  },
  methods: {
    ...mapActions('reporter', [
      'setActionManagement',
      'setBlockAttachmentManagement',
    ]),
    updateBlockFloat(value) {
      this.$emit('update', {
        float: value,
      });
    },
    updateBlockSize(value) {
      this.$emit('update', {
        size: value,
      });
    },
    updatePadding(key, value) {
      const otherField = key === 'top' ? 'bottom' : 'top';

      this.$emit('update', {
        padding: {
          [key]: value,
          [otherField]: this.block.padding ? this.block.padding[otherField] : 0,
        },
      });
    },
    hasTool(tool) {
      return this.tools.includes(tool);
    },
  },
};
</script>
<style lang="scss" scoped>
.editor__bar {
  position: absolute;
  left: 0;
  right: 0;
  top: -25px;
  width: fit-content;
  height: 50px;
  background: #ffffff;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 4px 0px, rgb(0 0 0 / 5%) 0px 3px 3px -2px,
    rgb(0 0 0 / 3%) 0px 1px 8px 0px;
  display: none;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  z-index: 3;
}

.divider {
  width: 1px;
  height: 100%;
  background: #f4f4f4;
}
</style>
