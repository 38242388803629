import { EditableBlock, EditableBlockType } from './enviro-table-footer-blocks';

export interface SampleKit {
  kit_id: number;
  upload_title: string;
  original_file_name: string;
  info_file_name: string | null;
  results_file_name: string | null;
}

export interface Sample {
  id: number;
  custom_title: string;
  original_title: string | null;
  lab_title: string;
  location_code: string | null;
  latitude: string | null;
  start_depth: number | null;
  end_depth: number | null;
  analyzed_date: string | null;
  sampled_date: string | null;
  lab_report_number: string | null;
  template_tab_id: number | null;
  sample_type: number | null;
  project_figure_layer_id: number | null;
  offline_user_id: number | null;
  matrix: number;
  created_at: Date;
}

export interface ProjectFigureLayer {
  id: number;
  geojson: any;
  title: string;
  type: string;
  marker_identifier: string | null;
  hidden_sub_folders?: any;
  area?: string;
  buffer?: number;
}

export interface Result {
  display_result: number;
  sample_id: number;
  chemical_id: number;
}

export interface Chemical {
  id: number;
  display_title: string;
  title: string;
  casno: string;
  group: string | null;
  soil_unit: string | null;
  water_unit: string | null;
  gas_unit: string | null;
  is_verified: boolean;
}

export interface SampleChemical extends Chemical {
  chemical_id: number;
  matrix: number;
  units: string;
  eql_result: number | null;
  total_or_filtered: string | null;
  rpd_acceptance: number | null;
}

export interface ProjectAssessed {
  id: number;
  title: string;
  has_statistics: boolean;
  statistics_non_detect_multiplier: number;
  statistics_rounding_to_dp: number;
  hidden_statistics: Array<string>;
}

export interface Guideline {
  id: number;

  data_1: number | null;
  data_alpha: string | null;
  units: string;

  media_2: string | null;
  depth_from: number | null;
  depth_to: number | null;
  pathways: string | null;
  type: string | null;

  scenario_id: number;
  document_id: number;
}

export type SampleNote = {
  note_label: string;
  note_message: string;
};

export type GuidelineDocument = {
  document_id: number;
  document: string;
  document_shorthand: string | null;
  acronym: string | null;
  country: string;
  short_url: string;
  long_url: any;
  type: any;
  media_2: any;
  soil_depth: any;
  hq_type: string | null;
  matrix: number;
  updated_at: string;
  formatted_title: string;
};

export type ItemGuidelineReason = {
  identifier?: number;
  reason: number;
};

export type SampleChemicalScenarioFactor = {
  factor: number;
  scenario_id: number;
};

export type ScenarioSet = {
  id: number;
  title: string;
  table_style_key: string;
  footer_settings: Record<EditableBlockType, EditableBlock> | null;
  nd_exceedance_cell_styling: EnviroCellStyle;
  above_nd_cell_styling: EnviroCellStyle;
  is_horizontal: boolean;
  print_settings: any;
  created_at: string;
};

export type ScenarioDocumentStyle = {
  short_url: string;
  title: string | null;
  hydrocarbon_settings: HydrocarbonSettings | null;
  exceedance_cell_styling: EnviroCellStyle;
};

export type ScenarioStyle = {
  criteria_type: 0 | 1;
  title: string | null;
  scenario_id: number | null;
  criteria_set_id: number | null;
  factor: number;
  documents: ScenarioDocumentStyle[];
  exceedance_cell_styling: EnviroCellStyle;
};

export type ScenarioSetWithStyles = ScenarioSet & {
  styles: ScenarioStyle[];
};

type GuidelineScenarioId = number;
type CriteriaSetId = number;

export type GuidelineScenario = {
  id: GuidelineScenarioId;
  landuse: string;
};

export type CriteriaSet = {
  id: CriteriaSetId;
  title: string;
  matrix: number;
  created_at: string;
};

export type ProjectScenarioId = number;

export enum CriteriaType {
  Landuse = 'landuse',
  Criteria = 'criteria',
}

export type ProjectScenario = {
  id: ProjectScenarioId;
  criteria_type: CriteriaType;
  scenario_id: GuidelineScenarioId;
  scenario: GuidelineScenario;
  factor: number;
  document_id: number;
  document?: GuidelineDocument;
  criteria_set_id: CriteriaSetId;
  criteria: CriteriaSet;
  options: {
    test_hydrocarbons: number;
    groundwater_depth: string;
    hh_scenario_id?: number;
    produce_consumption_percentage?: string | null;
  };
  hydrocarbon_settings: HydrocarbonSettings | null;
  scenario_text: string;
  has_processing_failure?: boolean;
  is_processed?: boolean;
  is_processing?: boolean;
  is_outdated?: boolean;
  has_hydro_sample_options_set?: boolean;
};

export type ChemicalGroup = {
  id: number;
  group_title: string;
  created_at: string;
};

export type ProjectScenarioGroup = {
  id: number;
  title: string;
  type: ProjectScenarioGroupType;
  chemical_profile_id: number | null;
};

export enum ProjectScenarioGroupType {
  General = 0,
  QAQC = 1,
  RPD = 2,
  Blanks = 3,
}

export const ProjectScenarioGroupTypeLabels = {
  [ProjectScenarioGroupType.General]: 'General',
  [ProjectScenarioGroupType.RPD]: 'Dup / Trip RPD',
  [ProjectScenarioGroupType.Blanks]: 'Blanks',
};

export type CompanyChemicalProfile = {
  id?: number;
  title: string;
};

export type GuidelineComment = {
  content: string;
};

export type HydrocarbonSettings = {
  soil_type?: string | null;
  depth_from?: number | null;
  depth_to?: number | null;
  pathway?: string | null;
};

export type EnviroCellStyle = {
  background_color: string | null;
  text_color: string | null;
  border_color: string | null;
  is_bold: boolean;
  is_italic: boolean;
  is_underlined: boolean;
};
