import { getMeasurementShortTitle } from '@/js/helpers/measurement.js';
import { getResultValue } from '@/js/helpers/result.js';
import { formatSampleDepth as helperFormatSampleDepth } from '@/js/helpers/sample.js';
import { getStylingPropertiesByMethodAsString } from '@/js/helpers/scenario.js';
import store from '@/js/store';
import { BLACK } from '../business-logic/color';
import {
  getChemicalFieldValue,
  getSampleFieldValue,
} from '../business-logic/evalu8';
import { getSampleTitle } from '../lib/olbm/layer/sample/utils';
import { NAMESPACE } from '../store';

/**
 * The Sample Chemicals Call-out Handler.
 */
class PopupHandler {
  constructor(project, selectedFigure, sample, allChemicalGroups) {
    this.project = project;
    this.selectedFigure = selectedFigure;
    this.sample = sample;
    this.allChemicalGroups = allChemicalGroups;
    this.resultExceedances = NAMESPACE.getGetter(
      store,
      'getResultExceedanceBySample'
    )(sample, 'result');
    this.scenarioSet = NAMESPACE.getState(store, 'scenarioSet');
  }

  getHiddenFormattedDepths() {
    return (
      this.selectedFigure.enviro_callout_filter?.hiddenFormattedDepths ?? []
    );
  }

  getPopupTable(
    style = { textColor: BLACK, textShadow: 'none', fontWeight: 'normal' },
    extraInfo = { scenarioSet: null, isScientificFormat: false }
  ) {
    const { textColor, textShadow, fontWeight } = style;
    const styleString = `color: ${textColor}; text-shadow: ${textShadow}; font-weight: ${fontWeight}`;
    const { content: headContent, columnCounts: headColumnCounts } =
      this._getHead();
    const bodyContent = this._getBodyContent(extraInfo, headColumnCounts);

    return bodyContent !== ''
      ? `<table class="table table-bordered builtin-callout-table" style="${styleString}">
          <thead>
            ${headContent}
          </thead>
          <tbody>
            ${bodyContent}
          </tbody>
         </table>`
      : undefined;
  }

  _getHead() {
    const sampleTitle = getSampleTitle(this.sample);
    const chemicalFieldColumns = this._getChemicalFieldColumns();
    const sampleDepthColumns = this._getSampleDepthColumns();

    return {
      content: `<tr>
                  <th>${sampleTitle}</th>
                  ${chemicalFieldColumns.join('')}
                  ${sampleDepthColumns.join('')}
                </tr>`,
      columnCounts: {
        chemicalFieldColumnCount: chemicalFieldColumns.length,
        sampleDepthColumnCount: sampleDepthColumns.length,
      },
    };
  }

  _getChemicalFieldColumns() {
    const {
      enviro_callout_filter: { selectedChemicalFields = [] },
    } = this.selectedFigure;
    return selectedChemicalFields.map((scf) => `<th>${scf.title}</th>`);
  }

  _getSampleDepthColumns() {
    const {
      enviro_callout_filter: { isDupVisible },
    } = this.selectedFigure;
    const result = [];
    const hiddenFormattedDepths = this.getHiddenFormattedDepths();
    const formattedDepth = this.formatSampleDepth(this.sample);

    if (
      this.checkHasResults(this.sample) &&
      !hiddenFormattedDepths.includes(formattedDepth)
    ) {
      result.push({
        startDepth: this.sample.start_depth ?? 0,
        formattedDepth,
        class: '',
      });
    }

    if (
      this.sample.duplicate &&
      this.checkHasResults(this.sample.duplicate) &&
      !hiddenFormattedDepths.includes(
        this.formatSampleDepth(this.sample.duplicate)
      ) &&
      isDupVisible
    ) {
      result.push({
        startDepth: this.sample.duplicate.start_depth ?? 0,
        formattedDepth: 'Dup',
        class: 'dupe',
      });
    }

    // The child_samples is null when the sample is a brand new one(created by the tool).
    this.sample.child_samples?.forEach((cs) => {
      const formattedDepth = this.formatSampleDepth(cs);

      if (
        this.checkHasResults(cs) &&
        !hiddenFormattedDepths.includes(formattedDepth)
      ) {
        result.push({
          startDepth: cs.start_depth ?? 0,
          formattedDepth,
          class: '',
        });
      }

      if (
        cs.duplicate &&
        this.checkHasResults(cs.duplicate) &&
        !hiddenFormattedDepths.includes(this.formatSampleDepth(cs.duplicate)) &&
        isDupVisible
      ) {
        result.push({
          startDepth: cs.duplicate.start_depth ?? 0,
          formattedDepth: 'Dup',
          class: 'dupe',
        });
      }
    });

    return result
      .sort((sd1, sd2) => sd1.startDepth - sd2.startDepth)
      .map((sd) => `<th class="${sd.class}">${sd.formattedDepth}</th>`);
  }

  _getBodyContent(extraInfo, headColumnCounts) {
    const {
      enviro_callout_filter: {
        isChemicalGroupsUsed,
        selectedChemicalGroups,
        selectedChemicalsByGroup,
        selectedSampleFields,
      },
      results,
    } = this.selectedFigure;
    const { chemicalFieldColumnCount, sampleDepthColumnCount } =
      headColumnCounts;
    const rows = [];

    for (const sampleField of selectedSampleFields) {
      const row = this._makeSampleFieldRow(sampleField, headColumnCounts);
      rows.push(row);
    }

    if (isChemicalGroupsUsed) {
      const _selectedChemicalGroups = selectedChemicalGroups.reduce(
        (accu, scg) => {
          const _scg = this.allChemicalGroups.find((cg) => cg.id === scg);
          if (_scg) {
            accu.push(_scg);
          }
          return accu;
        },
        []
      );
      for (const scg of _selectedChemicalGroups) {
        const resultsOfGroup = results.filter((r) =>
          (selectedChemicalsByGroup[scg.id] ?? []).includes(r.chemical_id)
        );
        if (resultsOfGroup.length === 0) {
          continue;
        }
        rows.push(`<tr>
                    <td colspan="${
                      chemicalFieldColumnCount + sampleDepthColumnCount + 1
                    }" class="bg-primary">${scg.group_title}</td>
                  </tr>`);
        for (const rog of resultsOfGroup) {
          const row = this._makeResultRow(rog, extraInfo);
          if (row) {
            rows.push(row);
          }
        }
      }
    } else {
      for (const r of results) {
        const row = this._makeResultRow(r, extraInfo);
        if (row) {
          rows.push(row);
        }
      }
    }

    return rows.join('');
  }

  _makeSampleFieldRow(sampleField, headColumnCounts) {
    const { chemicalFieldColumnCount, sampleDepthColumnCount } =
      headColumnCounts;
    const value = getSampleFieldValue(this.sample, sampleField);
    return `<tr>
              <td class="bg-primary">${sampleField.title}</td>
              <td colspan="${chemicalFieldColumnCount}"></td>
              <td colspan="${sampleDepthColumnCount}">${value}</td>
            </tr>`;
  }

  _makeResultRow(result, extraInfo) {
    const {
      enviro_callout_filter: { selectedChemicalFields = [] },
    } = this.selectedFigure;
    const resultColumns = this._getResultColumns(result, extraInfo);
    return resultColumns !== ''
      ? `<tr>
          <td>${result.display_title}</td>
          ${selectedChemicalFields
            .map((scf) => '<td>' + getChemicalFieldValue(result, scf) + '</td>')
            .join('')}
          ${resultColumns}
         </tr>`
      : undefined;
  }

  /**
   *
   * @param {SampleChemical} chemicalResult
   * @param {*} extraInfo
   * @returns
   */
  _getResultColumns(chemicalResult, extraInfo) {
    const {
      enviro_callout_filter: { visibleUnits, isDupVisible },
      hide_chemicals_without_exceedances: hideChemicalsWithoutExceedances,
      hide_non_detect_chemicals: hideNonDetectChemicals,
    } = this.selectedFigure;
    const { scenarioSet, isScientificFormat } = extraInfo;
    const { units } = chemicalResult;
    const resultColumns = [];

    const results = chemicalResult.sample_items;
    const hiddenFormattedDepths = this.getHiddenFormattedDepths();

    const formattedDepth = this.formatSampleDepth(this.sample);
    if (
      this.checkHasResults(this.sample) &&
      !hiddenFormattedDepths.includes(formattedDepth) &&
      (!visibleUnits || visibleUnits.includes(units))
    ) {
      const parentResult = results.find((r) => r.sample_id == this.sample.id);
      resultColumns.push({
        startDepth: this.sample.start_depth ?? 0,
        style: this._getResultExceedanceStyling(parentResult),
        class: 'text-center',
        displayResult: this.getDisplayResult(
          units,
          getResultValue(parentResult, scenarioSet, isScientificFormat)
        ),
        isNonDetect: !!parentResult?.prefix,
      });
    }

    if (
      this.sample.duplicate &&
      this.checkHasResults(this.sample.duplicate) &&
      !hiddenFormattedDepths.includes(
        this.formatSampleDepth(this.sample.duplicate)
      ) &&
      (!visibleUnits || visibleUnits.includes(units)) &&
      isDupVisible
    ) {
      const duplicateResult = results.find(
        (r) => r.sample_id == this.sample.duplicate.id
      );
      resultColumns.push({
        startDepth: this.sample.duplicate.start_depth ?? 0,
        style: this._getResultExceedanceStyling(duplicateResult),
        class: 'text-center',
        displayResult: this.getDisplayResult(
          units,
          getResultValue(duplicateResult, scenarioSet, isScientificFormat)
        ),
        isNonDetect: !!duplicateResult?.prefix,
      });
    }

    // The child_samples is null when the sample is a brand new one(created by the tool).
    this.sample.child_samples?.forEach((cs) => {
      const formattedDepth = this.formatSampleDepth(cs);
      if (
        this.checkHasResults(cs) &&
        !hiddenFormattedDepths.includes(formattedDepth) &&
        (!visibleUnits || visibleUnits.includes(units))
      ) {
        const childResult = results.find((r) => r.sample_id == cs.id);
        resultColumns.push({
          startDepth: cs.start_depth ?? 0,
          style: this._getResultExceedanceStyling(childResult),
          class: 'text-center',
          displayResult: this.getDisplayResult(
            units,
            getResultValue(childResult, scenarioSet, isScientificFormat)
          ),
          isNonDetect: !!childResult?.prefix,
        });
      }

      if (
        cs.duplicate &&
        this.checkHasResults(cs.duplicate) &&
        !hiddenFormattedDepths.includes(this.formatSampleDepth(cs.duplicate)) &&
        (!visibleUnits || visibleUnits.includes(units)) &&
        isDupVisible
      ) {
        const duplicateResult = results.find(
          (r) => r.sample_id == cs.duplicate.id
        );
        resultColumns.push({
          startDepth: cs.duplicate.start_depth ?? 0,
          style: this._getResultExceedanceStyling(duplicateResult),
          class: 'text-center',
          displayResult: this.getDisplayResult(
            units,
            getResultValue(duplicateResult, scenarioSet, isScientificFormat)
          ),
          isNonDetect: !!duplicateResult?.prefix,
        });
      }
    });

    if (
      hideChemicalsWithoutExceedances &&
      !resultColumns.find((rc) => rc.style !== '')
    ) {
      return '';
    }

    if (
      hideNonDetectChemicals &&
      resultColumns.filter((c) => !c.isNonDetect).length === 0
    ) {
      return '';
    }

    return resultColumns
      .sort((a, b) => a.startDepth - b.startDepth)
      .map(
        (item) =>
          `<td class="${item.class}" style="${item.style}">${item.displayResult}</td>`
      )
      .join('');
  }

  _getExceedanceProperties(result, exceedances) {
    if (exceedances.length == 0) {
      return [];
    }

    let stylingProperties = [];

    exceedances.forEach((exceedance) => {
      const styleByScenario = NAMESPACE.getGetter(
        store,
        'getStyleByScenario'
      )(exceedance);

      let highestValue = exceedance.exceedances.find(
        (i) => i.item_id == result.id
      ).value;

      let cellStyling = styleByScenario?.exceedance_cell_styling;
      if (exceedance.criteria_type === 'landuse' && result.prefix) {
        cellStyling = this.scenarioSet?.nd_exceedance_cell_styling || {
          background_color: '#f8f1d7',
        };

        highestValue = Infinity;
      }

      const properties = getStylingPropertiesByMethodAsString(cellStyling);

      stylingProperties.push({
        highestValue,
        properties,
      });
    });

    return stylingProperties;
  }

  _getResultExceedanceStyling(result) {
    if (!result) {
      return '';
    }

    const exceedances = this.resultExceedances;

    let stylingProperties = [];

    // get landuse exceedances
    const landuseExceedances = exceedances.filter(
      (e) =>
        e.criteria_type == 'landuse' &&
        e.exceedances.findIndex((i) => i.item_id == result.id) != -1
    );

    stylingProperties = [
      ...stylingProperties,
      ...this._getExceedanceProperties(result, landuseExceedances),
    ];

    // get criteria exceedances
    const criteriaExceedances = exceedances.filter(
      (e) =>
        e.criteria_type == 'criteria' &&
        e.exceedances.findIndex((i) => i.item_id == result.id) != -1
    );

    stylingProperties = [
      ...stylingProperties,
      ...this._getExceedanceProperties(result, criteriaExceedances),
    ];

    let styling = '';

    stylingProperties
      .sort((a, b) => a.highestValue - b.highestValue)
      .forEach((style) => {
        styling += style.properties;
      });

    return styling;
  }

  checkHasResults(sample) {
    const { sampled_date: sampledDate, sample_type: sampleType } = sample;
    const {
      enviro_callout_filter: { visibleTypes, visibleDates },
    } = this.selectedFigure;

    if (
      (visibleDates && !visibleDates.includes(sampledDate)) ||
      (visibleTypes && !visibleTypes.includes(sampleType))
    ) {
      return false;
    }

    return NAMESPACE.getGetter(store, 'checkHasResults')(
      this.selectedFigure,
      sample
    );
  }

  formatSampleDepth(sample) {
    return (
      helperFormatSampleDepth(
        sample,
        getMeasurementShortTitle(this.project.measurement_type)
      ) || ''
    );
  }

  // specifically for asbestos, user needs to manually update result based on the comment
  getDisplayResult(units, display_result) {
    if (units == 'comment' && (display_result == 0 || display_result == 1)) {
      display_result = 'COMMENT';
    }

    if (units == 'detect') {
      if (display_result == 0) {
        display_result = 'ABSENT';
      }
      if (display_result == 1) {
        display_result = 'PRESENT';
      }
    }

    return display_result;
  }
}

export default PopupHandler;
