import proj4 from 'proj4';
import { get as getProjection, equivalent, getTransform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { applyTransform } from 'ol/extent';
import * as common from './common';
import * as nz from './nz';
import * as au from './au';
import * as us from './us';
import * as gb from './gb';
import * as ca from './ca';

export { common, nz, au, us, gb };

// Projection registration
const allProjectionConfigs = [
  ...common.projectionConfigs,
  ...nz.projectionConfigs,
  ...au.projectionConfigs,
  ...us.projectionConfigs,
  ...gb.projectionConfigs,
  ...ca.projectionConfigs,
];

for (let item of allProjectionConfigs) {
  const { code, proj4Def: def } = item;
  if (def) {
    proj4.defs(code, def);
  }
}
register(proj4);
for (let item of allProjectionConfigs) {
  // bbox format: [maxy, minx, miny, maxx]
  const { code, bbox } = item;
  if (bbox) {
    // The following logic is from https://openlayers.org/en/latest/examples/reprojection-by-code.html
    const projection = getProjection(code);
    const fromLonLat = getTransform(common.EPSG_4326, projection);

    let worldExtent = [bbox[1], bbox[2], bbox[3], bbox[0]];
    projection.setWorldExtent(worldExtent);

    // approximate calculation of projection extent,
    // checking if the world extent crosses the dateline
    if (bbox[1] > bbox[3]) {
      worldExtent = [bbox[1], bbox[2], bbox[3] + 360, bbox[0]];
    }
    const extent = applyTransform(worldExtent, fromLonLat, undefined, 8);
    projection.setExtent(extent);
  }
}
nz.afterRegistration();

export function getProjectionNumber(code) {
  return parseInt(code.substring('EPSG:'.length), 10);
}

function getSupportedProjectionConfigs(country) {
  let result = [...common.projectionConfigs];
  if (country === 'NZ') {
    result = [...result, ...nz.projectionConfigs];
  } else if (country === 'AU') {
    result = [...result, ...au.projectionConfigs];
  } else if (country === 'US') {
    result = [...result, ...us.projectionConfigs];
  } else if (country === 'GB') {
    result = [...result, ...gb.projectionConfigs];
  } else if (country === 'CA') {
    result = [...result, ...ca.projectionConfigs];
  }
  return result;
}

function getSupportedProjections(country) {
  return getSupportedProjectionConfigs(country).map((item) =>
    getProjection(item.code)
  );
}

export const EPSG_4326_EXTENT = getProjection(common.EPSG_4326).getExtent();

export { getProjection };

export function getProjectionConfig(code) {
  return allProjectionConfigs.find((item) => item.code === code);
}

export function getSupportedProjectionOptions(country) {
  return getSupportedProjectionConfigs(country)
    .map(({ code, name }) => {
      return { code, label: `${code} ${name}` };
    })
    .sort((a, b) => getProjectionNumber(a.code) - getProjectionNumber(b.code));
}

export function checkIsSupportedProjection(country, projection) {
  const supportedProjections = getSupportedProjections(country);
  return supportedProjections.find((item) =>
    equivalent(item, getProjection(projection))
  );
}

export function convertToWGS84(fromProjectionCode, latitude, longitude) {
  const fromProjection = getProjection(fromProjectionCode);
  const toProjection = proj4.WGS84;

  return proj4(fromProjection, toProjection, [longitude, latitude]);
}

// Convert from WGS84 (aka EPSG:4326)
export function convertFromWGS84(toProjectionCode, latitude, longitude) {
  const fromProjection = proj4.WGS84;
  const toProjection = proj4.Proj(toProjectionCode);
  const fLatitude = parseFloat(latitude);
  const fLongitude = parseFloat(longitude);

  if (isNaN(fLatitude) || isNaN(fLongitude)) {
    throw new Error('Invalid latitude or longitude');
  }

  const projectedCoords = proj4(fromProjection, toProjection, [
    fLongitude,
    fLatitude,
  ]);

  return {
    longitude: projectedCoords[0], // X
    latitude: projectedCoords[1], // Y
  };
}
