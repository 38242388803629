import GeometryType from 'ol/geom/GeometryType';
import { Circle, Fill, Stroke, Text } from 'ol/style';
import Style, { createEditingStyle } from 'ol/style/Style';
import { checkIsVertexCoordinate } from '../utils';
import getMapFont from './getMapFont';
import createAreaStyle from './createAreaStyle';
import createOutlineStyle from './createOutlineStyle';
import { breakTextIntoLines } from '@component-library/utils';

const editingStyle = createEditingStyle();
const getSnappedToVertexDefault = (map, featureBeingModified, pointerCoord) => {
  return (
    !featureBeingModified ||
    checkIsVertexCoordinate(
      map,
      featureBeingModified.getGeometry(),
      pointerCoord,
      5
    )
  );
};

/**
 * Create a style used by sketches created by interactions such as Draw and Modify.
 * @param {ol.Map} map
 * @param {Object} options
 *  @param {Function} options.getPointerTip
 * @returns {Function} A StyleFunction.
 */
export default function createDrawingStyle(map, options) {
  const { getPointerTip, getSnappedToVertex = getSnappedToVertexDefault } =
    options;
  return function (feature, resolution) {
    const geom = feature.getGeometry();
    const gt = geom.getType();
    let style = [...editingStyle[gt]];
    if (gt === GeometryType.POINT) {
      // Use a different style for the point sketch if it is not a vertex.
      const featureBeingModified = feature.get('features')?.[0];
      const snappedToVertex = getSnappedToVertex(
        map,
        featureBeingModified,
        geom.getCoordinates()
      );
      if (!snappedToVertex) {
        style.splice(
          0,
          1,
          new Style({
            image: new Circle({
              radius: 6,
              fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
              }),
              stroke: new Stroke({
                color: 'rgba(0, 153, 255, 1)',
                width: 1.5,
              }),
            }),
            zIndex: Infinity,
          })
        );
      }

      const pointerTip = getPointerTip(snappedToVertex);
      if (pointerTip) {
        style = [
          ...style,
          new Style({
            text: new Text({
              text: breakTextIntoLines(pointerTip, 30)
                // The left padding doesn't work, so add a space as the left padding
                // .map((item) => ' ' + item)
                .join('\n'),
              font: getMapFont(),
              fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',
              }),
              backgroundFill: new Fill({
                color: 'rgba(0, 0, 0, 0.4)',
              }),
              padding: [4, 4, 4, 4],
              textAlign: 'left',
              offsetX: 15,
            }),
          }),
        ];
      }
    } else if (gt === GeometryType.POLYGON || gt === GeometryType.CIRCLE) {
      // Show area.
      style = [...style, createAreaStyle(map, feature, true)];
    } else if (gt === GeometryType.LINE_STRING) {
      style = [...style, ...createOutlineStyle(map, feature, true)];
    }

    return style;
  };
}
