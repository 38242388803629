export enum EditableBlockType {
  SCENARIOS,
  ADOPTED_CRITERIA,
  CRITERIA_COMMENTS,
  CRITERIA_ANALYTES,
  CUSTOM_NOTES,
  RESULT_NOTES,
  CHEMICAL_FACTOR,
  GUIDELINE_CHANGE,
  GUIDELINE_NOTE,
  LAB_REPORT_NUMBER,
}

export type DataItem = {
  type: 'text' | 'variable';
  value: string;
};

export type EditableBlock = {
  title: string | null;
  is_visible: boolean;
  scenario_text?: DataItem[];
  criteria_text?: DataItem[];
  notes?: CustomNote[];
  has_shaded_title?: boolean;
  hide_hydrocarbon_text?: boolean;
};

export type CustomNote = {
  key?: string;
  text: string | null;
  is_visible: boolean;
  is_conditional?: boolean;
};
