import Vue from 'vue';
import Vuex from 'vuex';
import api from './api';
import {
  createBlock,
  getNewBlockOrder,
  getNewBlockIndex,
  resetChildGroupBlocks,
} from './helpers/block-manager.js';
import { loadFontByName } from './helpers/font-manager.js';
import { createDefaultTheme } from './helpers/theme-manager';
import { createHeadingTree } from './helpers/heading-numbering';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    loadingReport: true,
    loadingReportError: null,
    reportToken: null,
    report: null,
    updating: false,
    editable: false,
    blocks: [],

    blockManagementProperties: null,
    mediaManagementProperties: null,
    actionManagementProperties: null,
    blockAttachmentProperties: null,
    showReportManagementModal: false,

    confirmationProperties: null,
    draggingBlockProperties: {
      draggingBlock: null,
      draggingOverBlock: null,
      draggingOverEmptyBlock: null,
      position: null,
    },
    blockActionProperties: null,
    pageHeadings: [],
    imagePreviewUrl: null,

    isMapExpandedId: false,
  },
  actions: {
    /**
     * Report
     */
    setToken({ commit }, token) {
      return commit('SET_TOKEN', token);
    },
    async selectReport({ commit, dispatch }, slug) {
      commit('SET_LOADING_REPORT', true);
      commit('SET_LOADING_REPORT_ERROR', null);

      try {
        const { data } = await api.getReport(slug);

        commit('UPDATE_PROJECT', data.project, {
          root: true,
        });

        if (!data.report.theme) {
          // The default theme is created by Admin.
          data.report.theme = createDefaultTheme(1, data.project.company.id);
        }

        commit('SELECT_REPORT', {
          report: data.report,
          blocks: data.blocks,
        });

        window.document.title = data.report.title || 'Untitled report';

        dispatch('setPageHeadings');

        commit('SET_LOADING_REPORT', false);
      } catch (e) {
        commit('SET_LOADING_REPORT', false);
        if (e.response) {
          if (e.response.status == 404) {
            commit(
              'SET_LOADING_REPORT_ERROR',
              'This report could not be found.'
            );
          } else if (e.response.status == 401) {
            commit('SET_LOADING_REPORT_ERROR', 'Permission denied.');
          } else {
            commit(
              'SET_LOADING_REPORT_ERROR',
              'An unexpected error occurred, please try again later.'
            );
          }
        }

        throw e;
      }
    },
    async updateReport({ commit, state }, fields) {
      commit('SET_UPDATING', true);

      const { data } = await api.updateReport(state.report, fields);

      window.document.title = data.report.title || 'Untitled report';

      commit('UPDATE_REPORT', data.report);
      commit('SET_MEDIA_MANAGEMENT', null);
      commit('SET_UPDATING', false);
    },
    setUpdating({ commit }, updating) {
      commit('SET_UPDATING', updating);
    },
    setEditable({ commit }, editable) {
      commit('SET_EDITABLE', editable);
    },
    setIsLoadingReport({ commit }, isLoading) {
      commit('SET_LOADING_REPORT', isLoading);
    },

    /**
     * Root level Blocks
     */
    async createBlock(
      { commit, state, dispatch },
      {
        type,
        position,
        selected_block_id,
        group_block_id,
        slideshow_block_id,
        slide_id,
        create_with_fields = null,
        is_slide_figure = false,
      }
    ) {
      let block = createBlock(
        type,
        group_block_id,
        slideshow_block_id,
        slide_id
      );

      block.is_slide_figure = is_slide_figure;

      // maybe should move to own method?
      if (
        ['image', 'audio', 'video', 'iframe'].includes(type) &&
        !state.mediaManagementProperties
      ) {
        commit('SET_MEDIA_MANAGEMENT', {
          ...block,
          position,
          selected_block_id,
        });
        return;
      }

      if (
        ['map', 'table', 'insight', 'coverpage'].includes(type) &&
        !state.blockManagementProperties
      ) {
        commit('SET_BLOCK_MANAGEMENT', {
          ...block,
          position,
          selected_block_id,
        });
        return;
      }

      if (create_with_fields) {
        block = {
          ...block,
          ...create_with_fields,
        };

        commit('SET_MEDIA_MANAGEMENT', null);
      }

      const index = getNewBlockIndex(state.blocks, selected_block_id, position);
      const order = getNewBlockOrder(state.blocks, selected_block_id, position);

      commit('SET_UPDATING', true);

      const { data } = await api.createBlock(state.report.id, block, order);

      commit('CREATE_BLOCK', {
        block: data.block,
        index,
      });

      await dispatch('updateBlockOrdering', state.blocks);

      commit('SET_UPDATING', false);
    },
    async updateBlock({ commit, state, dispatch }, { id, updated_fields }) {
      const block = state.blocks.find((b) => b.id == id);
      const hasFloatProperty = 'float' in updated_fields;

      // if sets float and child block, then remove from group
      if (hasFloatProperty && block.group_block_id && updated_fields.float) {
        updated_fields.group_block_id = null;
      }

      commit('SET_UPDATING', true);
      const { data } = await api.updateBlock(id, updated_fields);

      const resetChildGroup =
        hasFloatProperty &&
        block.float &&
        !updated_fields.float &&
        !block.group_block_id;

      // if setting float and has children, set groups to null
      if (resetChildGroup) {
        await resetChildGroupBlocks(state.blocks, block, dispatch);
      }

      commit('UPDATE_BLOCK', {
        block,
        updatedBlock: data.block,
      });

      commit('SET_MEDIA_MANAGEMENT', null);

      if (resetChildGroup) {
        await dispatch('updateBlockOrdering', state.blocks);
      }

      commit('SET_UPDATING', false);
    },
    async updateBlockOrdering({ commit, state }, blocks) {
      let sortedBlocks = blocks
        .filter((b) => !b.group_block_id && !b.slideshow_block_id)
        .map((block, bIndex) => {
          return {
            id: block.id,
            order: bIndex,
          };
        });

      sortedBlocks.forEach((sBlock) => {
        sortedBlocks = [
          ...sortedBlocks,
          ...blocks
            .filter((b) => b.group_block_id == sBlock.id)
            .map((block, bIndex) => {
              return {
                id: block.id,
                order: bIndex,
              };
            }),
          ...blocks
            .filter((b) => b.slideshow_block_id == sBlock.id)
            .map((block, bIndex) => {
              return {
                id: block.id,
                order: bIndex,
              };
            }),
        ];
      });

      await api.updateBlockOrdering(state.report.id, sortedBlocks);

      commit('UPDATE_BLOCK_ORDERING', sortedBlocks);
    },
    async duplicateBlock({ commit, state, dispatch }, block_id) {
      commit('SET_UPDATING', true);

      const { data } = await api.duplicateBlock(block_id);

      commit('DUPLICATE_BLOCK', {
        block: data.block,
        blocks: data.blocks,
        index: state.blocks.findIndex((b) => b.id == block_id) + 1,
      });

      if (data.block.type == 'slideshow') {
        setTimeout(() => {
          dispatch(
            'scrollToElement',
            window.document.querySelector(`[data-block-id="${data.block.id}"]`)
          );
        }, 300); //refactor
      }

      await dispatch('updateBlockOrdering', state.blocks);

      commit('SET_UPDATING', false);
    },
    async updateBlockPosition(
      { commit, state, dispatch },
      { dragging_over_id, group_block_id }
    ) {
      const { draggingBlock, position } = state.draggingBlockProperties;

      const { id, slide_id, slideshow_block_id } = state.blocks.find(
        (b) => b.id == dragging_over_id
      );

      const index = getNewBlockIndex(state.blocks, id, position);
      const order = getNewBlockOrder(state.blocks, id, position);

      commit('UPDATE_BLOCK_POSITION', {
        id: draggingBlock.id,
        index,
      });

      commit('SET_UPDATING', true);

      const { data } = await api.updateBlock(draggingBlock.id, {
        group_block_id,
        slide_id,
        slideshow_block_id,
        order,
      });

      commit('UPDATE_BLOCK', {
        block: draggingBlock,
        updatedBlock: data.block,
      });

      await dispatch('updateBlockOrdering', state.blocks);

      commit('SET_UPDATING', false);
    },
    async deleteBlock({ commit, state, dispatch }, id) {
      commit('SET_UPDATING', true);

      await api.deleteBlock(id);

      const block = state.blocks.find((b) => b.id == id);
      if (block.float) {
        await resetChildGroupBlocks(state.blocks, block, dispatch);
      }

      commit('DELETE_BLOCK', block);

      await dispatch('updateBlockOrdering', state.blocks);
      dispatch('setPageHeadings');

      commit('SET_UPDATING', false);
    },
    async updateBlockActions({ commit, state }, actions) {
      commit('SET_UPDATING', true);

      const { id } = state.actionManagementProperties.block;

      const { data } = await api.updateBlockActions({ actions, block_id: id });

      commit('UPDATE_BLOCK_ACTIONS', data.actions);

      commit('SET_UPDATING', false);
    },
    async uploadBlockAttachment({ commit, state }, file) {
      const { id } = state.blockAttachmentProperties;

      const { data } = await api.uploadBlockAttachment(id, file);

      commit('CREATE_BLOCK_ATTACHMENT', data.attachment);
    },

    /**
     * Slide
     */
    async createSlide({ commit }, slide_data) {
      commit('SET_UPDATING', true);

      const { data } = await api.createSlide(slide_data);

      commit('CREATE_SLIDE', data.slide);

      commit('SET_UPDATING', false);
    },
    async duplicateSlide({ commit, dispatch }, slide_data) {
      commit('SET_UPDATING', true);

      const { data } = await api.duplicateSlide(slide_data);

      commit('DUPLICATE_SLIDE', { slide: data.slide, blocks: data.blocks });

      setTimeout(() => {
        dispatch(
          'scrollToElement',
          window.document.querySelector(`[data-slide-id="${data.slide.id}"]`)
        );
      }, 300); //refactor

      commit('SET_UPDATING', false);
    },
    async deleteSlide({ commit, dispatch }, data) {
      commit('SET_UPDATING', true);

      await api.deleteSlide(data);

      commit('DELETE_SLIDE', data);

      dispatch('setPageHeadings');

      commit('SET_UPDATING', false);
    },
    async updateSlideOrdering(
      { commit, state },
      { slideshow_block_id, slideBeenDragged, slideBeenDraggedOver, position }
    ) {
      commit('SET_UPDATING', true);

      const slideshow = state.blocks.find((b) => b.id == slideshow_block_id);

      commit('UPDATE_SLIDE_POSITION', {
        slideshow,
        been_dragged_in: slideBeenDragged.id,
        dragging_over_id: slideBeenDraggedOver.id,
        position,
      });

      const slideOrder = slideshow.slides.map((s, sIndex) => {
        return {
          id: s.id,
          order: sIndex,
        };
      });

      await api.updateSlideOrdering({
        block_id: slideshow.id,
        slideOrder,
      });

      commit('UPDATE_SLIDE_ORDERING', {
        slideshow,
        slideOrder,
      });

      commit('SET_UPDATING', false);
    },
    async modifySlideFigure({ commit }, slide_data) {
      commit('SET_UPDATING', true);

      const { data } = await api.modifySlideFigure(slide_data);

      commit('UPDATE_SLIDE_FIGURE', data.figure);

      commit('SET_MEDIA_MANAGEMENT', null);

      commit('SET_UPDATING', false);
    },
    async deleteSlideFigure({ commit, dispatch }, slide_data) {
      commit('SET_UPDATING', true);

      await api.deleteSlideFigure(slide_data);

      commit('DELETE_SLIDE_FIGURE', slide_data);

      await dispatch('setPageHeadings');

      commit('SET_UPDATING', false);
    },

    /**
     * Attachments
     */
    async updateAttachment({ commit, state }, { id, updated_fields }) {
      await api.updateAttachment(id, updated_fields);

      commit('UPDATE_ATTACHMENT', { id, updated_fields });
    },
    async deleteAttachment({ commit, state }, id) {
      await api.deleteAttachment(id);

      commit('DELETE_ATTACHMENT', id);
    },

    /**
     * Management
     */
    setBlockManagement({ commit }, block) {
      commit('SET_BLOCK_MANAGEMENT', block);
    },
    setMediaManagement({ commit }, block) {
      commit('SET_MEDIA_MANAGEMENT', block);
    },
    setActionManagement({ commit }, block) {
      commit('SET_ACTION_MANAGEMENT', block);
    },
    setConfirmation({ commit }, properties) {
      commit('SET_CONFIRMATION', properties);
    },
    setDraggingProperties({ commit }, properties) {
      commit('SET_DRAGGING_PROPERTIES', properties);
    },
    setBlockActionProperties({ commit }, id) {
      commit('SET_BLOCK_ACTION_PROPERTIES', id);
    },
    setBlockAttachmentManagement({ commit }, id) {
      commit('SET_BLOCK_ATTACHMENT_PROPERTIES', id);
    },
    toggleReportManagementModal({ commit }, show) {
      commit('TOGGLE_REPORT_MANAGEMENT_MODAL', show);
    },
    setPageHeadings({ commit }) {
      setTimeout(async () => {
        const headings = Array.from(
          window.document
            .getElementsByClassName('viewer__container')[0]
            .querySelectorAll('h2, [data-title]:not([data-title=""])')
        ).map((e) => {
          return {
            element: e.hasAttribute('data-title')
              ? window.document.querySelector(
                  `[data-slide-id="${e.getAttribute('data-slide-id')}"]`
                )
              : e,
            title: e.hasAttribute('data-title')
              ? e.getAttribute('data-title')
              : e.innerText,
          };
        });

        commit('SET_PAGE_HEADINGS', headings);
      }, 300);
    },
    scrollToElement({ state }, element) {
      const padding = 10;
      const yOffset =
        42 + 70 + (state.pageHeadings.length > 0 ? 50 : 0) + padding;

      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - yOffset,
        behavior: 'smooth',
      });
    },
    setImagePreviewUrl({ commit }, url) {
      commit('SET_IMAGE_PREVIEW_URL', url);
    },
    setIsMapExpanded({ commit }, isExpandedId) {
      commit('SET_IS_MAP_EXPANDED', isExpandedId);
    },
  },
  mutations: {
    /**
     * Report
     */
    SET_LOADING_REPORT(state, loading) {
      state.loadingReport = loading;
    },
    SET_LOADING_REPORT_ERROR(state, error) {
      state.loadingReportError = error;
    },
    SET_TOKEN(state, token) {
      state.reportToken = token;
    },
    SELECT_REPORT(state, { report, blocks }) {
      state.report = report;
      state.blocks = blocks.sort((a, b) => a.order - b.order);

      const theme = report.theme;
      if (theme) {
        loadFontByName(theme.heading_font);
        loadFontByName(theme.paragraph_font);
      }
    },
    UPDATE_REPORT(state, report) {
      state.report = {
        ...state.report,
        ...report,
      };

      const theme = state.report.theme;
      if (theme) {
        loadFontByName(theme.heading_font);
        loadFontByName(theme.paragraph_font);
      }
    },
    SET_UPDATING(state, updating) {
      state.updating = updating;
    },
    SET_EDITABLE(state, editable) {
      state.editable = editable;
    },

    /**
     * Root level Blocks
     */
    CREATE_BLOCK(state, { block, index }) {
      state.blocks.splice(index, 0, block);
    },
    UPDATE_BLOCK(state, { block, updatedBlock }) {
      Object.keys(updatedBlock).forEach((key) => {
        block[key] = updatedBlock[key];
      });
    },
    DUPLICATE_BLOCK(state, { block, blocks, index }) {
      state.blocks.splice(index, 0, block);

      state.blocks = [...state.blocks, ...blocks];
    },
    UPDATE_BLOCK_ORDERING(state, slideOrder) {
      slideOrder.forEach(({ id, order }) => {
        let block = state.blocks.find((b) => b.id == id);

        block.order = order;
      });

      state.blocks = state.blocks.sort((a, b) => a.order - b.order);
    },
    UPDATE_BLOCK_POSITION(state, { id, index }) {
      const blockBeenDraggedIndex = state.blocks.findIndex((b) => b.id == id);
      const blockBeenDragged = state.blocks[blockBeenDraggedIndex];

      state.blocks.splice(blockBeenDraggedIndex, 1);
      state.blocks.splice(index, 0, blockBeenDragged);
    },
    DELETE_BLOCK(state, { type, id }) {
      state.blocks.splice(
        state.blocks.findIndex((b) => b.id == id),
        1
      );

      if (type == 'slideshow') {
        state.blocks = state.blocks.filter((b) => b.slideshow_block_id != id);
      }
    },
    UPDATE_BLOCK_ACTIONS(state, actions) {
      const { block } = state.actionManagementProperties;

      block.actions = actions;

      state.actionManagementProperties = null;
    },
    CREATE_BLOCK_ATTACHMENT(state, attachment) {
      const { id } = state.blockAttachmentProperties;

      const block = state.blocks.find((b) => b.id == id);

      block.attachments.push(attachment);
    },

    /**
     * Slide
     */
    CREATE_SLIDE(state, slide) {
      const slideshowBlock = state.blocks.find(
        (b) => b.id == slide.slideshow_block_id
      );

      slideshowBlock.slides.push({
        ...slide,
        figure: null,
      });
    },
    DUPLICATE_SLIDE(state, { slide, blocks }) {
      const { slideshow_block_id } = slide;

      const slideshowBlock = state.blocks.find(
        (b) => b.id == slideshow_block_id
      );

      slideshowBlock.slides.push(slide);

      state.blocks = [...state.blocks, ...blocks];
    },
    DELETE_SLIDE(state, { slideshow_block_id, slide_id }) {
      const slideshowBlock = state.blocks.find(
        (b) => b.id == slideshow_block_id
      );

      slideshowBlock.slides.splice(
        slideshowBlock.slides.findIndex((s) => s.id == slide_id),
        1
      );

      state.blocks = state.blocks.filter((b) => b.slide_id != slide_id);
    },
    UPDATE_SLIDE_POSITION(
      state,
      { slideshow, been_dragged_in, dragging_over_id, position }
    ) {
      let slides = slideshow.slides;

      const blockBeenDraggedIndex = slides.findIndex(
        (b) => b.id == been_dragged_in
      );

      let blockBeenDragged = slides[blockBeenDraggedIndex];

      slides.splice(blockBeenDraggedIndex, 1);

      const draggingOverBlockIndex = slides.findIndex(
        (b) => b.id == dragging_over_id
      );

      slides.splice(
        position == 'left'
          ? draggingOverBlockIndex
          : draggingOverBlockIndex + 1,
        0,
        blockBeenDragged
      );
    },
    UPDATE_SLIDE_ORDERING(state, { slideshow, slideOrder }) {
      slideOrder.forEach(({ id, order }) => {
        let slide = slideshow.slides.find((b) => b.id == id);

        slide.order = order;
      });
    },
    UPDATE_SLIDE_FIGURE(state, figure) {
      const { slideshow_block_id, slide_id } = figure;

      const slideshowBlock = state.blocks.find(
        (b) => b.id == slideshow_block_id
      );

      const slideshowSlide = slideshowBlock.slides.find(
        (s) => s.id == slide_id
      );

      slideshowSlide.figure = figure;
    },
    DELETE_SLIDE_FIGURE(state, { slideshow_block_id, slide_id }) {
      const slideshowBlock = state.blocks.find(
        (b) => b.id == slideshow_block_id
      );

      const slideshowSlide = slideshowBlock.slides.find(
        (s) => s.id == slide_id
      );

      slideshowSlide.figure = null;
    },

    /**
     * Attachments
     */
    UPDATE_ATTACHMENT(state, { id, updated_fields }) {
      const attachments = state.blocks.find(
        (b) => b.id == state.blockAttachmentProperties.id
      ).attachments;

      let attachment = attachments.find((a) => a.id == id);

      Object.keys(updated_fields).forEach((key) => {
        attachment[key] = updated_fields[key];
      });
    },
    DELETE_ATTACHMENT(state, id) {
      const attachments = state.blocks.find(
        (b) => b.id == state.blockAttachmentProperties.id
      ).attachments;

      attachments.splice(
        attachments.findIndex((a) => a.id == id),
        1
      );
    },

    /**
     * Management
     */
    SET_BLOCK_MANAGEMENT(state, block) {
      if (!block) {
        state.blockManagementProperties = null;
        return;
      }

      if (!block.settings) {
        block.settings = {};
      }

      state.blockManagementProperties = block;
    },
    SET_MEDIA_MANAGEMENT(state, block) {
      state.mediaManagementProperties = block;
    },
    SET_ACTION_MANAGEMENT(state, block) {
      if (!block) {
        state.actionManagementProperties = null;
        return;
      }

      if (block.type == 'map') {
        state.actionManagementProperties = {
          block,
          figure: block,
        };
        return;
      }

      state.actionManagementProperties = {
        block,
        figure: state.blocks
          .find((b) => b.id == block.slideshow_block_id)
          .slides.find((s) => s.id == block.slide_id).figure,
      };
    },
    SET_CONFIRMATION(state, properties) {
      state.confirmationProperties = properties;
    },
    SET_DRAGGING_PROPERTIES(state, properties) {
      state.draggingBlockProperties = {
        ...state.draggingBlockProperties,
        ...properties,
      };
    },
    SET_BLOCK_ACTION_PROPERTIES(state, id) {
      if (!id) {
        state.blockActionProperties = null;
        return;
      }

      const block = state.blocks.find((b) => b.id == id);

      if (block.actions.length == 0) {
        return;
      }

      state.blockActionProperties = block;
    },
    SET_BLOCK_ATTACHMENT_PROPERTIES(state, id) {
      const block = state.blocks.find((b) => b.id == id);

      state.blockAttachmentProperties = block;
    },
    TOGGLE_REPORT_MANAGEMENT_MODAL(state, show) {
      state.showReportManagementModal = show;
    },
    SET_PAGE_HEADINGS(state, headings) {
      state.pageHeadings = headings;
    },
    SET_IMAGE_PREVIEW_URL(state, url) {
      state.imagePreviewUrl = url;
    },
    SET_IS_MAP_EXPANDED(state, isExpandedId) {
      state.isMapExpandedId = isExpandedId;
    },
  },
  getters: {
    getRootBlocks: (state) => {
      return state.blocks.filter((b) => !b.slideshow_block_id);
    },
    getSlideFigureType:
      (state) =>
      ({ slideshow_block_id, slide_id }) => {
        const slideshowBlock = state.blocks.find(
          (b) => b.id == slideshow_block_id
        );

        return slideshowBlock.slides.find((s) => s.id == slide_id)?.figure
          ?.type;
      },
    getSlideBlocks: (state) => (slideshow_block_id, slide_id) => {
      return state.blocks.filter(
        (b) =>
          b.slideshow_block_id == slideshow_block_id && b.slide_id == slide_id
      );
    },
    getThemePropertyByKey: (state) => (key) => {
      const { theme } = state.report;
      return theme ? theme[key] : null;
    },
    hasReportNavigation: (state) => {
      return (
        state.report &&
        state.report.display_navigation &&
        state.pageHeadings.length > 0
      );
    },
    hasReportFooter: (state) => {
      return state.report && state.report.display_footer;
    },
    /**
     * This object is used to number headings in the blocks.
     */
    headingTree: (state, getters) => {
      // A heading source is a block with headings which show in the HeadingNavbar.
      // There are two kinds of heading source:
      // 1) Text blocks
      // 2) Coverpages in slides whose "Include in header" is ticked.
      const headingSources = getters.getRootBlocks.reduce((accu, rootBlock) => {
        if (rootBlock.type === 'text') {
          accu.push(rootBlock);
        } else if (rootBlock.type === 'slideshow') {
          accu = rootBlock.slides.reduce((accu2, slide) => {
            const { figure } = slide;
            if (
              figure &&
              figure.type === 'coverpage' &&
              figure.settings.include_in_heading &&
              figure.settings.title
            ) {
              accu2.push(figure);
            }

            const slideBlocks = getters.getSlideBlocks(rootBlock.id, slide.id);
            const slideTextBlocks = slideBlocks
              .filter((slideBlock) => {
                return slideBlock.type === 'text';
              })
              .sort((sb1, sb2) => sb1.order - sb2.order);
            accu2.push(...slideTextBlocks);

            return accu2;
          }, accu);
        }
        return accu;
      }, []);
      const feed = headingSources.map((headingSource) => {
        const { id: blockId } = headingSource;

        switch (headingSource.type) {
          case 'text':
            return { blockId, textContent: headingSource.content };
          case 'coverpage':
            return {
              blockId,
              textContent: `<h2>${headingSource.settings.title}</h2>`,
            };
        }

        throw new Error(`"${headingSource.type}" is not a heading source.`);
      });

      return createHeadingTree(feed);
    },
  },
};
