import generalMixin from '@/js/mixins/general';
import {
  type Field as ChemicalField,
  ChemicalFieldKey,
  CHEMICAL_FIELDS as allChemicalFields,
} from '@/js/modules/project/data-table/helpers/chemical-utils';
import {
  type Field as SampleField,
  allFields as allSampleFields,
} from '@/js/modules/project/data-table/helpers/table-fields';
import { CriteriaType, ProjectScenario } from '@component-library/enviro';

enum CriteriaTypeNumber {
  Landuse = 0,
  Criteria = 1,
}

type GuidelineScenarioId = number;
type CriteriaSetId = number;
export type SampleChemicalId = number;

type ScenarioDocumentStyle = {
  short_url: string;
};

type ScenarioStyle = {
  scenario_id: GuidelineScenarioId;
  factor: number;
  documents: ScenarioDocumentStyle[];
  criteria_set_id: CriteriaSetId;
  criteria_type: CriteriaTypeNumber;
  title?: string;
};

export function getOrderedScenarios(
  scenarios: ProjectScenario[]
): ProjectScenario[] {
  const criteriaTypes: CriteriaType[] = [
    CriteriaType.Landuse,
    CriteriaType.Criteria,
  ];
  const result = [...scenarios];
  result.sort(
    (s1, s2) =>
      criteriaTypes.indexOf(s1.criteria_type) -
      criteriaTypes.indexOf(s2.criteria_type)
  );
  return result;
}

export type SampleChemicalExceed = {
  chemicals: SampleChemicalId[];
  criteria_type: CriteriaType;
  document_id: number;
  factor: number;
  highest_value: number;
  scenario_id: GuidelineScenarioId;
  criteria_set_id: CriteriaSetId;
};

export type ChemicalId = number;

export type Chemical = {
  id: ChemicalId;
  title: string;
};

export type SampleChemical = {
  casno: string;
  chemical_id: ChemicalId;
  display_title: string;
  exceeds: SampleChemicalExceed[];
  id: SampleChemicalId;
  primary_title: string;
  title: string;
  units: string | null;
  eql_result?: number | null;
  rpd_acceptance?: number | null;
  total_or_filtered?: 1 | 0 | null;
};

export type ChemicalGroup = {
  id: number;
  group_title: string;
  items: ChemicalGroupItem[];
};

export type ChemicalGroupItem = {
  id: number;
  chemical_id: number;
};

const checkShouldSkipSample = (sample, hiddenFormattedDepths) => {
  const formattedDepth = generalMixin.methods.formatSampleDepth(sample);
  return hiddenFormattedDepths.includes(formattedDepth);
};

export function getAllSampleIdsFromSample(
  sample,
  hiddenFormattedDepths
): number[] {
  const result: number[] = [];

  if (!checkShouldSkipSample(sample, hiddenFormattedDepths)) {
    result.push(sample.id as number);
  }

  if (
    sample.duplicate &&
    !checkShouldSkipSample(sample.duplicate, hiddenFormattedDepths)
  ) {
    result.push(sample.duplicate.id as number);
  }

  sample.child_samples?.forEach((item) => {
    if (!checkShouldSkipSample(item, hiddenFormattedDepths)) {
      result.push(item.id as number);
    }

    if (
      item.duplicate &&
      !checkShouldSkipSample(item.duplicate, hiddenFormattedDepths)
    ) {
      result.push(item.duplicate.id as number);
    }
  });

  return result;
}

export type HydrocarbonSettings = {
  soil_type?: string | null;
  depth_from?: number | null;
  depth_to?: number | null;
  pathway?: string | null;
};

export type HydrocarbonSettingsParameter =
  | HydrocarbonSettings
  | null
  | undefined;

export const HydrocarbonSettingsUtil = {
  checkIsEmpty(hs: HydrocarbonSettingsParameter) {
    return hs === null || hs === undefined;
  },
  checkIsEqual(
    this,
    hs1: HydrocarbonSettingsParameter,
    hs2: HydrocarbonSettingsParameter
  ): boolean {
    if (this.checkIsEmpty(hs1)) {
      return this.checkIsEmpty(hs2);
    } else if (this.checkIsEmpty(hs2)) {
      return this.checkIsEmpty(hs1);
    } else {
      return (
        hs1!.soil_type === hs2!.soil_type &&
        hs1!.depth_from === hs2!.depth_from &&
        hs1!.depth_to === hs2!.depth_to &&
        hs1!.pathway === hs2!.pathway
      );
    }
  },
};

export const ScenarioUtil = {
  checkIsEqual(s1: ProjectScenario, s2: ProjectScenario) {
    return (
      s1.criteria_type === s2.criteria_type &&
      s1.criteria_set_id === s2.criteria_set_id &&
      s1.scenario_id === s2.scenario_id &&
      s1.document_id === s2.document_id &&
      s1.factor === s2.factor &&
      HydrocarbonSettingsUtil.checkIsEqual(
        s1.hydrocarbon_settings,
        s2.hydrocarbon_settings
      )
    );
  },
};

function normalizeField<
  F extends { key?: string | ChemicalFieldKey; template_field_id?: number }
>(allFields: F[], field: F): F {
  const _field = allFields.find(
    (f) =>
      (field.key !== undefined && f.key === field.key) ||
      (field.template_field_id !== undefined &&
        f.template_field_id === field.template_field_id)
  );
  // _field is undefined if it is a Gather field.
  return _field ? { ..._field, ...field } : field;
}

export function getSampleFieldValue(sample, field: SampleField): any {
  field = normalizeField(allSampleFields, field);
  if (field.template_field_id === undefined) {
    switch (field.key) {
      case 'sampled_depth':
        return (
          field.parseFilterDisplayTitle!({
            start_depth: sample.start_depth,
            end_depth: sample.end_depth,
          }) ?? ''
        );
      case 'lab_sample_type':
        return (
          field.parseFilterDisplayTitle!({ value: sample.lab_sample_type }) ??
          ''
        );
      case 'sampled_elevation':
        return (
          field.parseFilterDisplayTitle!({
            start_elevation: sample.start_elevation,
            end_elevation: sample.end_elevation,
          }) ?? ''
        );
      default:
        return (field.key !== undefined ? sample[field.key] : undefined) ?? '';
    }
  } else {
    const { input_values_for_enviro_callouts: ivs } = sample;
    return (
      ivs.find(
        (iv) =>
          iv.template_field_id === field.template_field_id &&
          iv.template_section_index === 0
      )?.formatted_value ?? ''
    );
  }
}

export function getChemicalFieldValue(
  sampleChemical: SampleChemical,
  field: ChemicalField
): any {
  field = normalizeField(allChemicalFields, field);
  switch (field.key) {
    case ChemicalFieldKey.UNIT_FIELD:
      return field.parse!(sampleChemical.units);
    case ChemicalFieldKey.EQL_FIELD:
      return field.parse!(sampleChemical.eql_result);
    case ChemicalFieldKey.RPD_FIELD:
      return field.parse!(sampleChemical.rpd_acceptance);
    case ChemicalFieldKey.CASNO_FIELD:
      return sampleChemical.casno;
    case ChemicalFieldKey.T_OR_F_FIELD:
      return sampleChemical.total_or_filtered === 1 ? 'Yes' : 'No';
  }
}
