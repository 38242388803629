<template>
  <div
    class="slideshow__figure"
    :class="{
      active: isActiveSlide,
      'slideshow__figure--full-screen':
        slide.figure &&
        slide.figure.settings &&
        slide.figure.settings.display_full_screen,
      'slideshow__figure--is-expanded':
        slide.figure && slide.figure.id === isMapExpandedId,
    }"
  >
    <template v-if="editable">
      <editor-bar
        v-if="slide.figure"
        :block="block"
        :tools="[
          slide.figure.type != 'audio' ? 'settings' : '',
          'delete',
          typeHasTool(slide.figure.type, 'media') ? 'media' : '',
        ]"
        @setBlockManagement="setBlockManagement(slide.figure)"
        @setMediaManagement="setMediaManagement(slide.figure)"
        @delete="
          () =>
            setConfirmation({
              header: 'Are you sure you want to remove this figure?',
              submit: () =>
                deleteSlideFigure({
                  slideshow_block_id: block.id,
                  slide_id: slide.id,
                }),
              isDelete: true,
            })
        "
      />

      <div v-else class="grid__container">
        <h5 class="fw-bolder">Select a media panel</h5>
        <h6 class="text-muted pb-3 mb-0">
          The block will appear here and can be explained in the left column.
        </h6>

        <block-selector-grid
          @createBlock="(type) => createBlockByType(type)"
          type="figure"
        />
      </div>
    </template>

    <component
      v-if="slide.figure && isActiveSlide"
      :is="getBlock"
      :block="slide.figure"
      :isSlide="true"
      @update="
        (data) =>
          modifySlideFigure({
            slide_id: slide.id,
            ...data,
          })
      "
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import blocks from '../index.js';
import EditorBar from '../../../modules/editor/components/EditorBar.vue';
import BlockSelectorDropdown from '../../../modules/editor/components/BlockSelectorDropdown.vue';
import BlockSelectorGrid from '../../../modules/editor/components/BlockSelectorGrid.vue';

export default {
  props: {
    block: Object,
    slide: Object,
    currentSlide: Number,
  },
  components: {
    EditorBar,
    BlockSelectorDropdown,
    BlockSelectorGrid,
  },
  computed: {
    ...mapState({
      editable: (state) => state['reporter'].editable,
      isMapExpandedId: (state) => state.reporter.isMapExpandedId,
    }),
    getBlock() {
      return blocks.getComponentNameFromId(this.slide.figure.type);
    },
    isActiveSlide() {
      return this.slide.id == this.currentSlide;
    },
  },
  methods: {
    ...mapActions('reporter', [
      'createBlock',
      'updateBlock',
      'setBlockManagement',
      'setMediaManagement',
      'deleteSlideFigure',
      'setConfirmation',
      'modifySlideFigure',
    ]),
    typeHasTool(type, tool) {
      return blocks.typeHasTool(type, tool);
    },
    createBlockByType(type) {
      this.createBlock({
        slideshow_block_id: this.block.id,
        slide_id: this.slide.id,
        type,
        is_slide_figure: true,
      });
    },
  },
  beforeCreate() {
    this.$options.components = {
      ...this.$options.components,
      ...blocks.components,
    };
  },
};
</script>
<style lang="scss" scoped>
.add__media-btn {
  top: 20px;
}

.slideshow__figure {
  position: sticky;
  top: calc(56px + 70px);
  padding: 0px !important;
  grid-row: 1;

  display: none;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}

.slideshow__figure.active {
  display: flex;
}

.slideshow__figure:nth-child(1) {
  background: #343a40;
  color: #ffffff;
}

.slideshow__figure .editor__bar {
  top: 20px;
}

.slideshow__figure.active:hover .editor__bar {
  display: flex;
}

.figure__placeholder {
  height: 100%;
  width: 100%;
  background-image: url('/images/media-bg.svg'); //#f4f5f5 //d9dbdb
  background-color: transparent;
  background-position: center center;
  background-size: 100%;
  background-repeat: repeat;
}

.grid__container {
  position: absolute;
  text-align: center;
}

.slideshow__figure--is-expanded {
  z-index: 20;
}
</style>
