import actions from './actions.js';
import getters from './getters.ts';
import mutations from './mutations.js';

const _getDefaultState = () => {
  return {
    is_exporting: import.meta.env
      ? import.meta.env.VITE_PREVIEW_EXCEL_EXPORT === 'true'
      : false,
    is_export_preview: import.meta.env
      ? import.meta.env.VITE_PREVIEW_EXCEL_EXPORT === 'true'
      : false,
    is_loading_export_settings: false,
    export_settings: {},
    is_hydrocarbon_export: import.meta.env
      ? import.meta.env.VITE_IS_HYDROCARBON_EXCEL_EXPORT === 'true'
      : false,
    hydrocarbon_export_type:
      import.meta.env &&
      import.meta.env.VITE_IS_HYDROCARBON_EXCEL_EXPORT == 'true'
        ? 'only-hydrocarbons'
        : null,

    load_paginated_samples_pending: false,
    load_paginated_samples_complete: null,
    load_all_paginated_samples: [],

    load_chemicals_pending: false,
    load_paginated_chemicals_complete: null,
    load_chemicals_complete: [],

    loaded_results: false,
    load_results_pending: false,
    load_results_complete: [],

    load_gather_values_complete: null,

    load_all_data_error: false,

    load_additional_data_pending: false,

    total_number_of_samples: 0,
    total_number_of_chemicals: 0,
    all_chemical_groups: [],
    sample_fields: [],
    chemical_fields: [],
    sample_notes: [],

    filters: {},
    updating_filters: false,

    load_chemical_units_pending: false,
    load_chemical_units_complete: [],

    editing_data: null,
    table_only_view: false,

    table_settings: {},
  };
};

export const getDefaultState = () => {
  return _getDefaultState();
};

const state = _getDefaultState();

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
