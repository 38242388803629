<script setup lang="ts">
import { ref, PropType, watch } from 'vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import InputTextFloating from '@component-library/components/InputTextFloating.vue';
import NotifyModal from '@component-library/components/NotifyModal.vue';
import { Sample } from '@component-library/enviro';
import { updateSample, deleteSample } from './api';
import { useToastStore } from '@component-library/store/toasts';
const toast = useToastStore();

const props = defineProps({
  sample: {
    type: Object as PropType<Sample>,
    required: true,
  },
});
const emit = defineEmits([
  'close',
  'itemUpdated',
  'itemDeleted',
  'onToggleShowDeleteModal',
]);

watch(
  () => props.sample,
  () => {
    title.value = props.sample.custom_title;
  }
);

const showDeleteSampleModal = ref(false);
const title = ref<string>(props.sample.custom_title);
const isDeleting = ref(false);
const isUpdating = ref(false);

const submitUpdateItem = async () => {
  try {
    isUpdating.value = true;

    const fieldsToUpdate = {
      custom_title: title.value,
    };

    await updateSample(props.sample.id, fieldsToUpdate);

    emit('itemUpdated', {
      ...props.sample,
      ...fieldsToUpdate,
    });
    emit('close');
  } catch (e) {
    toast.error('Failed to update item, try again.');
    throw e;
  } finally {
    isUpdating.value = false;
  }
};

const onDeleteSample = async () => {
  try {
    isDeleting.value = true;

    await deleteSample(props.sample.id);

    onShowDeleteModal(false);
    emit('itemDeleted', props.sample.id);
    emit('close');
  } catch (e) {
    toast.error('Failed to delete sample, try again.');
    throw e;
  } finally {
    isDeleting.value = false;
  }
};

const onShowDeleteModal = (isVisible: boolean) => {
  showDeleteSampleModal.value = isVisible;
  emit('onToggleShowDeleteModal', isVisible);
};
</script>
<template>
  <div>
    <div
      class="bg-dark text-white d-flex justify-content-between align-items-center p-3"
    >
      <h6 class="mb-0">Editing Item</h6>

      <button class="btn btn-danger btn-sm" @click="onShowDeleteModal(true)">
        <i class="fal fa-trash-alt" />
      </button>
    </div>
    <div class="p-2" @mousedown.stop>
      <InputTextFloating
        v-model="title"
        name="title"
        label="What is this item called?"
        required
      />
    </div>
    <div class="d-flex bg-light py-2 px-3">
      <button class="btn btn-flat me-2" @click="emit('close')">Cancel</button>
      <ButtonSpinner
        type="submit"
        class="btn btn-primary flex-fill"
        :is-loading="isUpdating"
        @click.native.prevent="submitUpdateItem"
      >
        Save
      </ButtonSpinner>
    </div>

    <NotifyModal
      v-if="showDeleteSampleModal"
      @close="onShowDeleteModal(false)"
      @submit="onDeleteSample"
      headerMessage="Are you sure you want to delete this item?"
      bodyMessage="Please be aware that deleting this item will remove all traces of this item."
      :isDelete="true"
      :isSubmitting="isDeleting"
    />
  </div>
</template>

<style scoped>
.bg-dark {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.bg-light {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
